import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUserContext } from '../../App.js';

library.add(fas)

function Navbar () {
    const [navbar, setNavbar] = useState(false)
    const [dropdownProfile, setDropdownProfile] = useState(false)
    const navigate = useNavigate()
    const {userData, setUserData} = useUserContext();
    
    const burgerMenuClick = () => {
        setNavbar(!navbar)
    }

    const dropDownProfileClick = () => {
        setDropdownProfile(!dropdownProfile)
    }

    const logout = () => {
        localStorage.removeItem('token')
    }

    function isOpen (stateId) {
        return stateId ? 'show' : ''
    }

    return (
        <div>
            <header id='page-topbar'>
                <div className='navbar-header'>
                    <div className='d-flex'>
                        <div className='navbar-brand-box'>
                            <a href='/' className='logo logo-light'>
                                <span className='logo-lg'>
                                    <img src='https://bafkreihlybqbtns5c3rvxzyizwmb4jopgtinlimym3seul5yekrcnfgudy.ipfs.nftstorage.link/' alt='' style={{ height: '60px' }} />
                                </span>
                                <span className='logo-sm'>
                                    <h3><b>VNETWORK.CC</b></h3>
                                </span>
                            </a>
                        </div>

                        <button type='button' className='btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light collapsed' onClick={burgerMenuClick}><i className='fa fa-fw fa-bars'></i></button>
                    </div>

                    <div className='d-flex'>
                        <div className='dropdown d-inline-block'>
                            <button type='button' className='btn header-item waves-effect' onClick={dropDownProfileClick}>
                                <span className='d-none d-xl-inline-block ms-1' id='navbar-username'></span>
                                <i className='mdi mdi-chevron-down d-none d-xl-inline-block'></i>
                            </button>

                            <div className={`dropdown-menu dropdown-menu-end ${isOpen(dropdownProfile)}`}>
                                <a className='dropdown-item text-danger' href='/' onClick={logout}>
                                    <i className='bx bx-power-off font-size-16 align-middle me-1 text-danger'></i>
                                    <span key='t-logout'>Logout</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className='topnav'>
                <div className='container-fluid'>
                    <nav className='navbar navbar-light navbar-expand-lg topnav-menu'>
                        <div className={`collapse navbar-collapse active ${isOpen(navbar)}`} id='topnav-menu-content'>
                            <ul className='navbar-nav active'>
                                <li className='nav-item'>
                                    <a className='nav-link dropdown-toggle arrow-none' onClick={() => {navigate('/panel')}} id='topnav-dashboard' role='button'>
                                        <FontAwesomeIcon icon='fa-solid fa-house' />
                                        <span key='t-dashboards'> Dashboard</span>
                                    </a>
                                </li>
                                <li className='nav-item'>
                                    <a className='nav-link dropdown-toggle arrow-none' onClick={() => {navigate('/manager')}} id='topnav-manager' role='button'>
                                        <FontAwesomeIcon icon='fa-solid fa-server' />
                                        <span key='t-dashboards'> Manager</span>
                                    </a>
                                </li>
                                <li className='nav-item'>
                                    <a className='nav-link dropdown-toggle arrow-none' onClick={() => {navigate('/premium')}} id='topnav-dashboard' role='button'>
                                        <FontAwesomeIcon icon='fa-solid fa-crown' />
                                        <span key='t-dashboards'> Premium</span>
                                    </a>
                                </li>
                                <li className='nav-item'>
                                    <a className='nav-link dropdown-toggle arrow-none' onClick={() => {navigate('/faq')}} id='topnav-dashboard' role='button'>
                                        <FontAwesomeIcon icon='fa-solid fa-book' />
                                        <span key='t-dashboards'> F.A.Q</span>
                                    </a>
                                </li>
                                {userData.rank === "1" && (
                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle arrow-none" onClick={() => {navigate('/admin')}} id="topnav-pages" role="button">
                                            <FontAwesomeIcon icon='fa-solid fa-user' />
                                            <span key="t-apps"> Administration</span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Navbar
