import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function Login () {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const usernameHandle = event => {
    setUsername(event.target.value)
  }
  const passwordHandle = event => {
    setPassword(event.target.value)
  }

  const LogIn = async () => {
    if(localStorage.getItem('token')){
      navigate('/panel');
    }else{
      const response = await fetch(
        'https://api.vnetwork.cc/api/v1/auth/login',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: username,
            password: password
          })
        }
      )

      if (!response.ok) {
        navigate('/login')
      }

      const data = await response.json()

      if (data.code === 200) {
        toast('Successful connection !', {
          icon: '👏',
          style: {
            borderRadius: '10px',
            background: '#32394e',
            color: '#fff',
          },
        });
        localStorage.setItem('token', data.token)
        setTimeout(() => {
          navigate('/panel')
        }, 2000)
      }
      
      if (data.code === 301) {
        toast('Your account does not exist !', {
          icon: '😢',
          style: {
            borderRadius: '10px',
            background: '#32394e',
            color: '#fff',
          },
        });

        setTimeout(() => {
          navigate('/register')
        }, 2000)
      }

      if (data.code === 403) {
        toast('Invalid username or password !', {
          icon: '😢',
          style: {
            borderRadius: '10px',
            background: '#32394e',
            color: '#fff',
          },
        });
      }
    }
  }
  return (
    <div>
      <div className='mb-3'>
        <h1 className='text-center'>
          <b>Welcome Back 🤙</b>
        </h1>
        <h6 className='text-center'>We are happy to see you again !</h6>
      </div>
      <div className='mb-3'>
        <label id='username-input' className='form-label'>
          Username <b className='text-danger'>*</b>
        </label>
        <input
          type='text'
          className='form-control'
          id='username'
          placeholder='Enter username'
          value={username}
          onChange={usernameHandle}
        ></input>
      </div>
      <div className='mb-3'>
        <label id='password-input' className='form-label'>
          Password <b className='text-danger'>*</b>
        </label>
        <input
          type='password'
          className='form-control'
          id='password'
          placeholder='Enter Password'
          value={password}
          onChange={passwordHandle}
        ></input>
      </div>
      <div className='mt-3 d-grid'>
        <button
          className='btn btn-primary waves-effect waves-light'
          type='submit'
          onClick={() => LogIn()}
        >
          Log In
        </button>
      </div>
      <div className='mt-3 d-grid'>
        <span>
          <b>Need An Account ? </b>
          <a href='/register'>
            <b style={{ color: '#00A8FC' }}>Register</b>
          </a>
        </span>
      </div>
    </div>
  )
}

export default Login
