import React, { useState, useEffect, createContext, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css' // Ensure you import the styles for Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import './assets/css/app.min.css'
import './assets/css/icons.min.css'
import './assets/css/bootstrap.min.css'
import { Toaster } from 'react-hot-toast';
import Navbar from './components/navbar/navbar.js'
const UserContext = createContext({});
library.add(fas)
function App(props) {
    const [isAuthorized, setIsAuthorized] = useState(false)
    const navigate = useNavigate()
    const [userData, setUserData] = useState({});


    useEffect(() => {
        const checkAuth = async () => {
        try {
            const response = await fetch(
            'https://api.vnetwork.cc/api/v1/user/@me',
            {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                authorization: localStorage.getItem('token')
                }
            }
            )
            const data = await response.json()

            if (response.ok) {
              setUserData(data.user);
              setIsAuthorized(true);
            } else {
              setIsAuthorized(false);
              localStorage.removeItem("token");
              navigate('/login');
            }
        } catch (error) {}
        }

        checkAuth()
    }, [])

    if (!isAuthorized) {
      return null;
    }


    return (
      <div data-topbar='dark' data-layout='horizontal' data-layout-mode='dark'>
        <div id='layout-wrapper'>
          <UserContext.Provider value={{ userData, setUserData }}>
          
            <Navbar />

            <div className='main-content'>
              <Toaster />
              <div className='page-content'>
                <div className='container-fluid'>
                  {props.component}
                </div>
              </div>
            </div>
          </UserContext.Provider>
        </div>
        <div className='rightbar-overlay'></div>
        <script src='%PUBLIC_URL%/libs/jquery/jquery.min.js'></script>
        <script src='%PUBLIC_URL%/libs/bootstrap/js/bootstrap.bundle.min.js'></script>
        <script src='%PUBLIC_URL%/libs/metismenu/metisMenu.min.js'></script>
        <script src='%PUBLIC_URL%/libs/simplebar/simplebar.min.js'></script>
        <script src='%PUBLIC_URL%/assets/libs/node-waves/waves.min.js'></script>
        <script src='%PUBLIC_URL%/assets/libs/apexcharts/apexcharts.min.js'></script>

        <script src='%PUBLIC_URL%/assets/js/pages/dashboard.init.js'></script>
      </div>
    );
}

export function useUserContext() {
    return useContext(UserContext);
}

export default App;