import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function NavbarIndex () {
    const navigate = useNavigate()
    const [burger, setBurger] = useState(false);

    const setBurgerNav = (bool) => {
        setBurger(!bool);
    }

    return (
        <div>
            <nav class="navbar navbar-expand-lg navigation fixed-top sticky">
                <div class="container">
                    <a class="navbar-logo" href="index.html">
                        <img src="https://bafkreihlybqbtns5c3rvxzyizwmb4jopgtinlimym3seul5yekrcnfgudy.ipfs.nftstorage.link/" alt="" height="60px" class="logo logo-dark" />
                        <img src="https://bafkreihlybqbtns5c3rvxzyizwmb4jopgtinlimym3seul5yekrcnfgudy.ipfs.nftstorage.link/" alt="" height="60px" class="logo logo-light" />
                    </a>

                    <button onClick={() => {setBurgerNav(burger)}} type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
                        <i class="fa fa-fw fa-bars"></i>
                    </button>

                    <div class={`collapse navbar-collapse ${burger && burger == true ? "show" : ''}`} id="topnav-menu-content">
                        <ul class="navbar-nav ms-auto gap-2" id="topnav-menu" >
                            <li class="nav-item">
                                <a onClick={() => {navigate('/login')}} class="btn btn-outline-primary w-xs">Sign in</a>
                            </li>
                            <li class="nav-item">
                                <a onClick={() => {navigate('/register')}} class="btn btn-primary w-xs">Sign up</a>
                            </li>


                        </ul>

                    </div>
                </div>
            </nav>
        </div>
    )
}

export default NavbarIndex
