import React, { useEffect, useState } from 'react'

import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function Profile () {
    const [datePremium, setDatePremium] = useState("")
    const [profile, setProfile] = useState({})

    useEffect(() => {
        const profileEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/user/@me',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('token')
                    }
                }
            )

            const data = await response.json()
            if (response.ok) {
                var date = new Date(data.user.premiumExpire * 1000);

                var day = date.getDate();
                var month = date.getMonth() + 1;
                var year = date.getFullYear();
                setDatePremium(`${day}/${month}/${year}`);
                setProfile(data.user)
            }
        }
        
        profileEffect();

        const intervalId = setInterval(async () => {
            profileEffect();
        }, 2000);

        profileEffect();
        
        return () => {
			clearInterval(intervalId);
		};
    }, [])



    return (
        <div className='card'>
            <div className='card-body'>
                <h4 className='card-title mb-4'>User Profile</h4>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='pt-1'>
                            <div className='bg-light p-3 d-flex mb-3 rounded'>
                                <img
                                    src='https://i.imgur.com/vXzHaQe.png'
                                    className='avatar-sm rounded me-3'
                                />
                                <div className='flex-grow-1 placeholder-glow'>
                                    <h5 className='font-size-15 mb-2'>
                                        <a className={`text-body ${profile.username ? '' : 'placeholder'}`} > {profile.username ? profile.username : '0000000000'} </a>  {profile.premium && profile.premium != '0' && ( <span className='badge badge-soft-warning'> Premium </span> )}
                                    </h5>
                                    <p className={`mb-0 text-muted ${profile.username ? '' : 'placeholder'}`}>{profile.rank == '1' ? (<FontAwesomeIcon icon="fa-solid fa-crown" className='text-body align-middle' />) : <FontAwesomeIcon icon="fa-solid fa-user" className='text-body align-middle' />} {profile.rank == '1' ? 'Admin' : 'None'} </p>
                                </div>
                            </div>
                        </div>
                        {profile.premium != '0' && (
                            <div className='pt-1'>
                                <div className='bg-light p-3 d-flex mb-3 rounded'>
                                    <div className='flex-grow-1'>
                                        <h5 className='font-size-13 mb-2'>
                                            <a className={`text-body ${profile.username ? '' : 'placeholder'}`} > You have taken out a subscription which expires on {datePremium}</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;