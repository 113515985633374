import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import HCaptcha from "@hcaptcha/react-hcaptcha";
import toast from 'react-hot-toast';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function Register () {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const [captchaToken, setToken] = useState('');



  const usernameHandle = event => {
    setUsername(event.target.value)
  }
  const passwordHandle = event => {
    setPassword(event.target.value)
  }

  const SignUp = async () => {
    if(localStorage.getItem('token')){
      navigate('/panel');
    }else{
      const response = await fetch(
        'https://api.vnetwork.cc/api/v1/auth/register',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: username,
            password: password,
            captcha: captchaToken,
          })
        }
      )

      if (!response.ok) {
        navigate('/login')
      }

      const data = await response.json()

      if (data.code === 200) {
        toast('Successful connection !', {
          icon: '👏',
          style: {
            borderRadius: '10px',
            background: '#32394e',
            color: '#fff',
          },
        });

        localStorage.setItem('token', data.token)
        setTimeout(() => {
          navigate('/panel')
        }, 2000)
      }

      if (data.code === 301) {
        toast('It seems that this account already exists !', {
          icon: '😢',
          style: {
            borderRadius: '10px',
            background: '#32394e',
            color: '#fff',
          },
        });

        setTimeout(() => {
          navigate('/login')
        }, 2000)
      }

      if (data.code === 400) {
        toast('The captcha you provided is invalid !', {
          icon: '😢',
          style: {
            borderRadius: '10px',
            background: '#32394e',
            color: '#fff',
          },
        });

        setTimeout(() => {
          navigate('/register')
        }, 2000)
      }

      if (data.code === 401) {
        toast('You do not have access because the system was whitelisted !', {
          icon: '😢',
          style: {
            borderRadius: '10px',
            background: '#32394e',
            color: '#fff',
          },
        });
      }
    }
  }

  return (
    <div>
      <div className='mb-3'>
        <h1 className='text-center'>
          <b>Welcome👋!</b>
        </h1>
        <h6 className='text-center'>We are happy to see you !</h6>
      </div>
      <div className='mb-3'>
        <label id='username-input' className='form-label'>
          Username <b className='text-danger'>*</b>
        </label>
        <input
          type='text'
          className='form-control'
          id='username'
          placeholder='Enter username'
          value={username}
          onChange={usernameHandle}
        ></input>
      </div>
      <div className='mb-3'>
        <label id='password-input' className='form-label'>
          Password <b className='text-danger'>*</b>
        </label>
        <input
          type='password'
          className='form-control'
          id='password'
          placeholder='Enter Password'
          value={password}
          onChange={passwordHandle}
        ></input>
      </div>
      <div className='mb-3'>
        <label for='username' className='form-label'>
          Re-Password <b className='text-danger'>*</b>
        </label>
        <input
          type='password'
          className='form-control'
          id='password'
          placeholder='Enter the password again'
        ></input>
      </div>
            <HCaptcha
                sitekey="1deeffb4-19a7-4a04-bed9-344c39c48641"
                onVerify={setToken}
            />
      <div className='mt-3 d-grid'>
        <button
          className='btn btn-primary waves-effect waves-light'
          type='submit'
          onClick={() => SignUp()}
        >
          Sign Up !
        </button>
      </div>
      <div className='mt-3 d-grid'>
        <span>
          <b>Do you already have an account ?</b>{' '}
          <a href='/login'>
            <b style={{ color: '#00A8FC' }}>Login</b>
          </a>
        </span>
      </div>
    </div>
  )
}

export default Register
