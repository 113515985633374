import React, { useEffect, useState, createContext, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Server from './container/server.js';

library.add(fas)
const DataContext = createContext({});

export const DataContextProvider = ({ children }) => {
    const [data, setData] = useState({
        managerList: [],
        search: '',
        view: 'all',
        loading: true,
        tabIndex: 0,
        statistics: {
            totalServers: 0,
            totalTokens: 0,
        },
        refresh: "",
        checkedServer: []
    });

    return (
        <DataContext.Provider value={{ data, setData }}>
            {children}
        </DataContext.Provider>
    );
};


function Manager() {
    const [premium, setPremium] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        const profileEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/user/@me',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('token')
                    }
                }
            )

            const data = await response.json()
            if (response.ok) {
                console.log(data.user);
                if(data && data.user && data.user.premium != true){
                    navigate('/premium');
                }else{
                    setPremium(true);
                }
            }
        }
        
        profileEffect();
    }, [premium])

    return (
        <div>
            {premium === true && (
                <DataContextProvider>
                    <Server />
                </DataContextProvider>
            )}
        </div>
    )
}

export function useDataContext() {
    return useContext(DataContext);
}
export default Manager

