import React, { useEffect, useState } from 'react'

import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(fas)

function Faq () {
    const [data, setData] = useState({
        faq: [],
        tab: 0,
    })
    useEffect(() => {
        const serverEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/faq/get',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('token')
                    }
                }
            )

            const json = await response.json()
            if (response.ok) {
                setData({
                    ...data,
                    faq: json.data,
                    tab: 1,
                });
            }
        }

        serverEffect();
    }, [])
    return (
        <div>
            <div class="checkout-tabs">
                <div class="row">
                    <div class="col-lg-2">
                        <div class="nav flex-column nav-pills" >
                            {data.tab == 0 && (
                                <div className="placeholder-glow">
                                    <a className={`nav-link`}>
                                        <i className={`placeholder bx bx-support d-block check-nav-icon mt-4 mb-2`}></i>
                                        <p className="placeholder fw-bold mb-4">0000000000000000000000</p>
                                    </a>
                                    <a className={`nav-link`}>
                                        <i className={`placeholder bx bx-support d-block check-nav-icon mt-4 mb-2`}></i>
                                        <p className="placeholder fw-bold mb-4">0000000000000000</p>
                                    </a>
                                    <a className={`nav-link`}>
                                        <i className={`placeholder bx bx-support d-block check-nav-icon mt-4 mb-2`}></i>
                                        <p className="placeholder fw-bold mb-4">0000000000000000</p>
                                    </a>
                                </div>
                            )}
                            {data.faq.categorie && data.faq.categorie.map((item) => { return (
                                <a key={item.id} className={`nav-link ${item.id === data.tab ? 'active' : ''}`} onClick={() => {setData({ ...data, tab: item.id, });}}>
                                    <i className={`${item.icon}`}></i>
                                    <p className="fw-bold mb-4">{item.name}</p>
                                </a>
                            )})}
                        </div>
                    </div>
                    <div class="col-lg-10">
                        <div class="card">
                            <div class="card-body">
                                <div class="tab-content">
                                    <div className={`tab-pane fade placeholder-glow  ${0 === data.tab ? 'show active' : ''}`} role="tabpanel">
                                            <h4 class="card-title mb-5 placeholder">0000000000000000</h4>
                                            <div class="faq-box d-flex mb-4">
                                                <div class="flex-shrink-0 me-3 faq-icon">
                                                </div>
                                                <div class="flex-grow-1 ">
                                                    <h5 class="font-size-15 placeholder">000000000000000000000000000000</h5>
                                                </div>
                                            </div>
                                            <div class="faq-box d-flex mb-4">
                                                <div class="flex-shrink-0 me-3 faq-icon">
                                                </div>
                                                <div class="flex-grow-1 ">
                                                    <h5 class="font-size-15 placeholder">000000000000000000000000000000000000000000000000000000000000</h5>
                                                </div>
                                            </div>
                                            <div class="faq-box d-flex mb-4">
                                                <div class="flex-shrink-0 me-3 faq-icon">
                                                </div>
                                                <div class="flex-grow-1 ">
                                                    <h5 class="font-size-15 placeholder">000000000000000000000000000000000000000000000000000000</h5>
                                                </div>
                                            </div>
                                            <div class="faq-box d-flex mb-4">
                                                <div class="flex-shrink-0 me-3 faq-icon">
                                                </div>
                                                <div class="flex-grow-1 ">
                                                    <h5 class="font-size-15 placeholder">0000000000000000000000000000000000000</h5>
                                                </div>
                                            </div>
                                    </div>

                                    {data.faq.categorie && data.faq.categorie.map((item) => { return (
                                        <div className={`tab-pane fade  ${item.id === data.tab ? 'show active' : ''}`} role="tabpanel">
                                            <h4 class="card-title mb-5">{item.name}</h4>

                                            {data.faq.questions && data.faq.questions.map((row) => { 
                                                if(item.id == row.categorie) {
                                                return (
                                                    <div class="faq-box d-flex mb-4">
                                                        <div class="flex-shrink-0 me-3 faq-icon">
                                                            <i class="bx bx-help-circle font-size-20 text-success"></i>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <h5 class="font-size-15">{row.head}</h5>
                                                            <p class="text-muted">{row.description} </p>
                                                        </div>
                                                    </div>
                                                )
                                            }})}
                                        </div>
                                    )})}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq
