import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function Terms () {
    return (
    <div>
        <div className='mb-3'>
            <h1 className='text-center'>
                <b>General Terms and Conditions of Use (GTCU) for Vnetwork</b>
            </h1>
            <p>
                <b>Welcome to our online computer management service (vnerwork).</b><br></br> 
                Before using our platform, please read the following terms and conditions carefully.<br></br>
                By accessing our service, you agree to be bound by these terms and conditions.<br></br>
                If you do not accept these conditions, please do not use our service.<br></br><br></br>
                <b>1. Acceptance of the Terms</b><br></br>
                By accessing our service, you agree to be bound by these Terms and Conditions.<br></br>
                If you do not accept these conditions, please do not use our service.<br></br><br></br>
                <b>2. Use of the Service</b><br></br>
                2.1. You must be of legal age in your jurisdiction to use our service.<br></br>
                2.2. You are responsible for maintaining the confidentiality of your account and password. You are solely responsible for all activities that occur under your account.<br></br>
                2.3. You agree not to use our service for any illegal or unauthorised purpose.<br></br>
                2.4. You agree not to access our service in any unauthorised manner.<br></br>
                2.5. You agree not to interfere with the proper functioning of our service.<br></br>
                2.6. You agree to comply with all applicable laws and regulations when using our service.<br></br><br></br>
                <b>3. User data</b><br></br>
                3.1. You are solely responsible for all data that you upload, store or share via our service.<br></br>
                3.2. We do not claim any ownership rights in your data. However, by uploading, storing or sharing data via our service, you grant us a non-exclusive, non-transferable right to use, reproduce, modify and display such data solely for the purpose of providing and improving our service.<br></br>
                3.3. We will take all reasonable steps to protect the confidentiality and security of your data. However, we cannot guarantee the absolute security of data transmitted via the Internet.<br></br><br></br>
                <b>4. Fees and payments</b><br></br>
                4.1. The use of certain features of our service may be subject to charges.<br></br>
                4.2. You agree to pay all applicable fees in connection with your use of our service.<br></br>
                4.3. All payments are non-refundable.<br></br><br></br>
                <b>5. Modification of the Service and Conditions</b><br></br>
                5.1. We reserve the right to modify or discontinue our service at any time, temporarily or permanently, with or without notice.<br></br>
                5.2. We reserve the right to modify these Terms and Conditions at any time. Modifications will take effect as soon as they are published on our website. It is your responsibility to consult these conditions regularly to stay informed of any updates.<br></br>
                5.3. By continuing to use our service after the changes have been published, you accept the new conditions.<br></br><br></br>
                <b>6. Limitation of liability</b><br></br>
                6.1. To the fullest extent permitted by applicable law, our liability to you for any claim arising out of or in connection with your use of our service shall be limited to the amount, if any, paid by you to use our service.<br></br>
                6.2. In no event will we be liable for any indirect, incidental, special, punitive or consequential damages, including but not limited to loss of profits, data, use or other economic benefits, arising out of or in connection with your use of our service.<br></br><br></br>
                <b>7. Applicable law</b><br></br>
                7.1. These General Terms and Conditions of Use are governed by the laws in force in Switzerland.<br></br><br></br>
                <b>8. Contact Us</b><br></br>
                8.1. You can contact us by Telegram via https://t.me/willy4533<br></br>





            </p>
        </div>
    </div>
    )
}

export default Terms
