import React, { useEffect, useState, useRef  } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import toast from 'react-hot-toast';

import Info from './container/info.js';
import Console from './container/tabs/console.js';
import Token from './container/tabs/token.js';
import Additional from './container/tabs/additional.js';

library.add(fas)

function Manage () {
    const [premium, setPremium] = useState(false);
    const navigate = useNavigate()
    const { id } = useParams()
    const [tabIndex, setTabIndex] = useState(0);
    
    useEffect(() => {
        const profileEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/user/@me',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('token')
                    }
                }
            )

            const data = await response.json()
            if (response.ok) {
                console.log(data.user);
                if(data && data.user && data.user.premium != true){
                    navigate('/premium')
                }else{
                    setPremium(true);
                }
            }
        }
        
        profileEffect();
    }, [])
    if(!id){
        navigate('/manager') 
        return null
    }
    return (
    <div>
        {premium === true && (
            <div className='row'>
                <div className='col-xl-4'>
                    <div className='card'>
                        <Info />
                    </div>
                </div>
                <div className='col-xl-8'>
                    <div className='card'>
                        <div className='card-body'>
                            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                                <TabList className="nav nav-tabs nav-tabs-custom nav-justified mb-2">
                                    <Tab className={`nav-item d-none d-sm-block font-size-18 ${tabIndex === 0 ? 'text-primary' : ''}`}><b>Console</b></Tab>
                                    <Tab className={`nav-item d-none d-sm-block font-size-18 ${tabIndex === 1 ? 'text-primary' : ''}`}><b>Token(s)</b></Tab>
                                    <Tab className={`nav-item d-none d-sm-block font-size-18 ${tabIndex === 2 ? 'text-primary' : ''}`}><b>Additional(s) Info</b></Tab>
                                </TabList>

                                <TabPanel className="tab-pane">
                                    <Console />
                                </TabPanel>
                                <TabPanel className="tab-pane">                            
                                    <Token />
                                </TabPanel>
                                <TabPanel className="tab-pane">
                                    <Additional />
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </div>
    )
}

export default Manage
