import React, { useEffect, useState, useContext, } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import {useDataContext} from '../../panel.js';
library.add(fas)

function Tbody () {
    const { data, setData } = useDataContext();
    const navigate = useNavigate()
    const handleCheckboxChange = (event, item) => {
        if(data.checkedServer.includes(item)){
            const removeCheckedServer = data.checkedServer.filter(row => row !== item);
            setData({             
                ...data,
                checkedServer: removeCheckedServer,
                refresh: Math.random()
            });
        }else{
            setData({
                ...data,
                checkedServer: [...data.checkedServer, item],
                refresh: Math.random()
            });
        }
    };

    const GoToManage = (id, name) => {
            toast('Accessing ' + name + ' !', {
                icon: '👏',
                style: {
                    borderRadius: '10px',
                    background: '#32394e',
                    color: '#fff',
                },
            });

            setTimeout(() => {
                navigate('/manage/' + id);
            }, 600)
    }

    const formatUptime = (timestamp) => {
        const currentTime = Math.floor(Date.now() / 1000);
        const durationInSeconds = currentTime - timestamp;

        const months = Math.floor(durationInSeconds / (30 * 24 * 60 * 60));
        const days = Math.floor((durationInSeconds % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
        const hours = Math.floor((durationInSeconds % (24 * 60 * 60)) / (60 * 60));
        const minutes = Math.floor((durationInSeconds % (60 * 60)) / 60);
        const seconds = Math.floor(durationInSeconds % 60);

        const parts = [];
        if (months > 0) parts.push(`${months} month`);
        if (days > 0) parts.push(`${days} days`);
        if (hours > 0) parts.push(`${hours} hours`);
        if (minutes > 0) parts.push(`${minutes} minutes`);

        return parts.join(', ') || 'Less than a second';
    }

    return (
        <>
            <tbody>
                {data.loading === true && (
                    <>
                        {Array.from({ length: 4 }).map((_, index) => (
                            <tr key={index} className="placeholder-glow">
                                <td>
                                    <h5 className='placeholder'>00000</h5>
                                </td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                    <h5 className='placeholder'>00000000</h5>
                                </td>
                                <td>
                                    <h5 className='placeholder'>000000000000</h5>
                                </td>
                                <td><h5 className='placeholder'>000000</h5></td>
                                <td>
                                    <h5 className='placeholder'>00000000</h5>
                                </td>
                                <td>
                                    <h5 className='placeholder'>00000000000</h5>
                                </td>
                                <td>
                                    <h5 className='placeholder'>00000000</h5>
                                </td>
                            </tr>
                        ))}
                    </>
                )}
                {data.loading === false && data.managerList.filter(server => {
                    if (data.view === 'connected') {
                        return server.time > (Math.floor(Date.now() / 1000) - 60);
                    } else if (data.view === 'disconnected') {
                        return !(server.time > (Math.floor(Date.now() / 1000) - 60));
                    } else if (data.view === 'all') {
                        return true;
                    }
                })
                .filter(server => {
                    return data.search.trim() === '' || server.desktopName.toLowerCase().includes(data.search.toLowerCase());
                }).length === 0 && (
                    <tr>
                        <td colSpan="8">
                            <center>
                                <h3><FontAwesomeIcon icon="fa-solid fa-globe" className="text-danger" /> You have no server in this category <FontAwesomeIcon icon="fa-solid fa-globe" className="text-danger" /></h3>
                            </center>
                        </td>
                    </tr>
                )}
                {data.loading === false && data.managerList.length >= 1 && data.managerList.filter(server => {
                    if (data.view === 'connected') {
                        return server.time > (Math.floor(Date.now() / 1000) - 60);
                    } else if (data.view === 'disconnected') {
                        return !(server.time > (Math.floor(Date.now() / 1000) - 60));
                    } else if (data.view === 'all') {
                        return true;
                    }
                })
                .filter(server => {
                    return data.search.trim() === '' || server.desktopName.toLowerCase().includes(data.search.toLowerCase());
                })
                .map((server, index) => (
                    <tr key={index}>
                        <td>
                            <div className="form-check form-check-primary font-size-16">
                                <input className="form-check-input" checked={data.checkedServer.includes(server.serverId)} type="checkbox" onChange={(event) => { handleCheckboxChange(event, server.serverId) }} />
                            </div>
                        </td>
                        <td className='font-size-20' style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: "19px" }}>
                            {server.time > Math.floor(Date.now() / 1000) - 60 ? (
                                <FontAwesomeIcon icon="fa-solid fa-rss" className="text-success" />
                            ) : (
                                <FontAwesomeIcon icon="fa-solid fa-rss" className="text-danger" />
                            )}
                        </td>
                        <td>
                            <h5 className="text-truncate font-size-14 text-uppercase">{server.desktopName}</h5>
                            <p className="text-muted mb-0">{server.ip}</p>
                        </td>
                        <td>{formatUptime(server.time)}</td>
                        <td className='font-size-20' style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: "19px" }}>
                            {server.earnApp == 1 ? (
                                <FontAwesomeIcon className='text-success text-center' icon="fa-solid fa-money-bills" />
                            ) : (
                                <FontAwesomeIcon className='text-danger text-center' icon="fa-solid fa-money-bills" />
                            )}
                        </td>
                        <td>
                            <div className="avatar-group">
                                {server.token.length >= 1 && server.token.map((token, index) => (
                                    token.avatar !== "0" ? (
                                        <div className="avatar-group-item" key={index}>
                                            <a className="d-inline-block">
                                                <img src={`https://cdn.discordapp.com/avatars/${token.discordId}/${token.avatar}.png`} alt="" className="rounded-circle avatar-xs" onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src="https://i.imgur.com/vXzHaQe.png"; }} />
                                            </a>
                                        </div>
                                    ) : (
                                        <div className="avatar-group-item" key={index}>
                                            <a className="d-inline-block">
                                                <div className="avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-success text-white font-size-16 text-uppercase">
                                                        {token.username.charAt(0)}
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                    )
                                ))}
                            </div>
                        </td>
                        <td>
                            <div className="contact-links d-flex font-size-20" style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: "19px" }}>
                                <div className="flex-fill">
                                    {server.earnApp == 0 ? (
                                        <FontAwesomeIcon onClick={() => { GoToManage(server.serverId, server.desktopName) }} onMouseEnter={(e) => (e.target.style.color = '#8f5fe8')} onMouseLeave={(e) => (e.target.style.color = '#a6b0cf')} icon="fa-solid fa-eye" />
                                    ) : (
                                        <FontAwesomeIcon icon="fa-solid fa-xmark" className="text-danger" />
                                    )}
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </>
    )
}

export default Tbody;