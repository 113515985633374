import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function Privacy () {
    return (
    <div>
        <div className='mb-3'>
            <h1 className='text-center'>
                <b>Privacy Policy</b>
            </h1>
            <p>
                This Privacy Policy describes how we collect, use, and protect your information when you use our online computer management service.<br></br>
                By using our service, you consent to the collection and use of your information in accordance with this policy.<br></br><br></br>
                <b>Collection and Use of Information</b><br></br><br></br>
                We may collect several types of information when you use our service:<br></br><br></br>
                Personal identification information: When you register, we may ask you to provide personal identification information such as your name, email address, and billing information.<br></br>
                Device information: We may collect information about the devices you use to access our service, including device type, IP address, operating system, and browser.<br></br>
                Usage data: We may collect usage data, such as the pages you visit, the features you use, and the actions you take on our service.<br></br>
                We use the information collected to provide, maintain, and improve our service, as well as to communicate with you regarding your account and service updates.<br></br><br></br>
                <b>Protection of Information</b><br></br><br></br>
                We implement appropriate technical and organizational security measures to protect your information against unauthorized access, disclosure, alteration, or destruction.<br></br><br></br>
                <b>Sharing of Information</b><br></br><br></br>
                We do not share your personal information with third parties, except in the following cases:<br></br><br></br>
                When necessary to provide our service, such as processing payments or providing technical support.<br></br>
                When we are required to do so by law or to protect our rights, property, or safety, or those of others.<br></br><br></br>
                <b>Cookies</b><br></br><br></br>
                We use cookies and similar tracking technologies to collect information and enhance your user experience on our website.<br></br><br></br>
                <b>Changes to the Privacy Policy</b><br></br><br></br>
                We reserve the right to modify this Privacy Policy at any time. Changes will take effect upon posting on our website. We encourage you to regularly check this page for updates.<br></br><br></br>
                <b>Contact Us</b><br></br><br></br>
                If you have any questions regarding this Privacy Policy, please contact us at Telegram via https://t.me/willy4533<br></br><br></br>
                Last updated: 15/03/2024.





            </p>
        </div>
    </div>
    )
}

export default Privacy
