import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function ServersList () {
    const [users, setUsers] = useState([]);
    useEffect(() => {
        const serverEffect = async () => {
            try {
                const response = await fetch(
                    'https://api.vnetwork.cc/api/v1/admin/servers',
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': localStorage.getItem('token')
                        }
                    }
                );
                if (response.ok) {
                    const data = await response.json();
                    setUsers(data.data);
                } else {
                    // Handle error response
                }
            } catch (error) {
                // Handle fetch error
            }
        }

        const intervalId = setInterval(async () => {
            serverEffect();
        }, 2000);

        serverEffect();

        return () => {
			clearInterval(intervalId);
		};
    }, [])




    const formatUptime = (timestamp) => {
        const currentTime = Math.floor(Date.now() / 1000);
        const durationInSeconds = currentTime - timestamp;

        const months = Math.floor(durationInSeconds / (30 * 24 * 60 * 60));
        const days = Math.floor((durationInSeconds % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
        const hours = Math.floor((durationInSeconds % (24 * 60 * 60)) / (60 * 60));
        const minutes = Math.floor((durationInSeconds % (60 * 60)) / 60);
        const seconds = Math.floor(durationInSeconds % 60);

        const parts = [];
        if (months > 0) parts.push(`${months} month`);
        if (days > 0) parts.push(`${days} days`);
        if (hours > 0) parts.push(`${hours} hours`);
        if (minutes > 0) parts.push(`${minutes} minutes`);

        return parts.join(', ') || 'Less than a second';
    }

    const formatPremium = (timestamp) => {
        var date = new Date(timestamp * 1000);

        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    return (
        <>
            <div className='row'>
                <div className='col-xl-12'>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="table-responsive">
                                    <table className="table project-list-table table-nowrap align-middle table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Ip</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map((server, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <h5 className="text-truncate font-size-14 text-uppercase">{server.desktopName}</h5>
                                                        <p className="text-muted mb-0">{server.uuid}</p>
                                                    </td>
                                                    <td>{server.ip}</td>
                                                    <td className='font-size-20' style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: "19px" }}>
                                                        {server.utime > Math.floor(Date.now() / 1000) - 60 ? (
                                                            <FontAwesomeIcon icon="fa-solid fa-rss" className="text-success" />
                                                        ) : (
                                                            <FontAwesomeIcon icon="fa-solid fa-rss" className="text-danger" />
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServersList;