import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function Info () {
    const [server, setServer] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();

    const ramConverter = (size) => {
        const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
		const i = Math.floor(Math.log(size) / Math.log(1024));
		return size ? parseFloat((size / Math.pow(1024, i)).toFixed(2)) + ' ' + units[i] : '';
    }

    useEffect(() => {


        const serverEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/manage/' + id,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('token')
                    }
                }
            )

            const data = await response.json()
            if (response.ok) {
                setServer(data.data[0]);
            }else{
                navigate('/manager')
                return null;
            }
        }
        const intervalId = setInterval(async () => {
            serverEffect();
        }, 2000);

        serverEffect();

        return () => {
			clearInterval(intervalId);
		};
    }, [])

    return (
        <div className='card-body'>
            <h4 className='card-title mb-2 text-uppercase'>{server.desktopName}</h4>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className='pt-1'>
                        <div className='bg-light p-3 d-flex mb-3 rounded'>
                            <ul className="list-unstyled vstack gap-3 mb-4">
                                <li>
                                    <div className="d-flex">
                                        <FontAwesomeIcon icon="fa-solid fa-microchip" className="font-size-18 text-primary" />
                                        <div className="ms-3">
                                            <h6 className="mb-1 fw-semibold">CPU :</h6>
                                            <span className="text-muted">{server.cpu ? server.cpu : "CPU NOT FOUND"} ({server.core ? server.core : "0"} <FontAwesomeIcon icon="fa-solid fa-heart-pulse" className="text-danger" />)</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="d-flex">
                                        <FontAwesomeIcon icon="fa-solid fa-desktop" className="font-size-18 text-primary" />
                                        <div className="ms-3">
                                            <h6 className="mb-1 fw-semibold">GPU :</h6>
                                            <span className="text-muted">{server.gpu ? server.gpu : "GPU NOT FOUND"}</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="d-flex">
                                        <FontAwesomeIcon icon="fa-solid fa-laptop" className="font-size-18 text-primary" />
                                        <div className="ms-3">
                                            <h6 className="mb-1 fw-semibold">OS :</h6>
                                            <span className="text-muted">{server.os ? server.os : "OS NOT FOUND"}</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="d-flex">
                                        <FontAwesomeIcon icon="fa-solid fa-fingerprint" className="font-size-18 text-primary" />
                                        <div className="ms-3">
                                            <h6 className="mb-1 fw-semibold">UUID :</h6>
                                            <span className="text-muted">{server.uuid ? server.uuid : "UUID NOT FOUND"}</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="d-flex">
                                        <FontAwesomeIcon icon="fa-solid fa-address-book" className="font-size-18 text-primary" />
                                        <div className="ms-3">
                                            <h6 className="mb-1 fw-semibold">MAC ADDRESS :</h6>
                                            <span className="text-muted">{server.macAddress ? server.macAddress : "MAC ADDRESS NOT FOUND"}</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="d-flex">
                                        <FontAwesomeIcon icon="fa-solid fa-location-dot" className="font-size-18 text-primary" />
                                        <div className="ms-3">
                                            <h6 className="mb-1 fw-semibold">IP :</h6>
                                            <span className="text-muted">{server.ip ? server.ip : "IP NOT FOUND"}</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="d-flex">
                                        <FontAwesomeIcon icon="fa-solid fa-signal" className="font-size-18 text-primary" />
                                        <div className="ms-3">
                                            <h6 className="mb-1 fw-semibold">STATUS :</h6>
                                            {server.utime && server.utime > Math.floor(Date.now() / 1000) - 60 ? (
                                                <span className="text-success">Connected</span>
                                            ) : (
                                                <span className="text-danger">Disconnected</span>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </div>
                        <div className='bg-light p-3 d-flex mb-3 rounded'>
                            <div className='flex-grow-1'>
                                <h5 className="font-size-13 mb-3"><FontAwesomeIcon icon="fa-solid fa-chevron-right" className="text-primary" /> {((1 - (server.freeRam / server.totalRam )) * 100).toFixed(2)}% RAM ({ramConverter(server.totalRam)})</h5>
                                <div className='font-size-15 mb-2'>
                                            
                                    <div class="custom-progess mb-2">
                                        <div class="progress progress-sm" style={{backgroundColor: "#454957"}}>
                                            <div class="progress-bar bg-danger" role="progressbar" style={{width: ((1 - (server.freeRam / server.totalRam )) * 100).toFixed(2) + "%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <div class="avatar-xs progress-icon">
                                            <span class="avatar-title rounded-circle border border-danger">
                                                <FontAwesomeIcon icon="fa-solid fa-memory" className="text-danger" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Info;