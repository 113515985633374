import React, { useParams } from 'react'
import ReactDOM from 'react-dom/client'

import AppIndex from './AppIndex.js'
import reportWebVitals from './reportWebVitals.js'
import App from "./App.js";
import LandingPage from './landingpage.js'
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route
} from 'react-router-dom'

// Panel
import Dashboard from './components/panel/panel.js'
import Manager from './components/manager/panel.js'
import Manage from './components/manage/panel.js'
import Premium from './components/premium/panel.js'
import Faq from './components/faq/panel.js'
import Admin from './components/admin/panel.js'
// General
import Login from './components/auth/login.js'
import Register from './components/auth/register.js'
import NotFound from './components/general/404.js'
import Terms from './components/general/terms.js'
import Privacy from './components/general/privacy.js'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/panel' element={<App component={<Dashboard />} />} />
        <Route path='/faq' element={<App component={<Faq />} />} />
        <Route path='/manager' element={<App component={<Manager />} />} />
        <Route path='/manage/:id' loader={({ params }) => { console.log(params.id) }} element={<App component={<Manage />} />} />
        <Route path='/manage' loader={({ params }) => { console.log(params.id) }} element={<App component={<Manage />} />} />
        <Route path='/premium' loader={({ params }) => { console.log(params.id) }} element={<App component={<Premium />} />} />
        <Route path='/admin' loader={({ params }) => { console.log(params.id) }} element={<App component={<Admin />} />} />
        <Route path='/premium/:id' loader={({ params }) => { console.log(params.id) }} element={<App component={<Premium />} />} />
        <Route path='/login' element={<AppIndex component={<Login />} />} />
        <Route path='/register' element={<AppIndex component={<Register />} />} />
        <Route path='/terms' element={<AppIndex component={<Terms />} />} />
        <Route path='/privacy' element={<AppIndex component={<Privacy />} />} />
        <Route path="*" element={<AppIndex component={<NotFound />} />} />
      </Routes>
    </BrowserRouter>

)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
