import React, { useEffect, useState, } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import Tabs from './components/tabs.js';
import Search from './components/search.js';
import Tbody from './components/tables.js';
import {useDataContext} from '../panel.js';
import './styles.css';
library.add(fas)
function Server () {
    const { data, setData } = useDataContext();
    const [checkboxAll, setCheckboxAll] = useState(1);

    const handleCheckboxALL = () => {
        var serverIdList = [];
        if(checkboxAll == 1){
            data.managerList.map(item => {
                serverIdList.push(item.serverId);
            });
    
            setData({
                ...data,
                checkedServer: serverIdList,
                refresh: Math.random()
            });
            setCheckboxAll(0);
        }else{
            setCheckboxAll(1);
            setData({
                ...data,
                checkedServer: [],
                refresh: Math.random()
            });
        }
    };
    useEffect(() => {
        const managerEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/manager/get',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('token')
                    }
                }
            )
            const json = await response.json()
            if (response.ok) {
                if(json && json.totalServers && json.totalServers > 0){
                    var pushTable = [];
                    json.data.map(element => {
                        pushTable.push(element);
                    });
                    setData({
                        ...data,
                        managerList: pushTable,
                        loading: false,
                        statistics:{
                            ...data.statistics,
                            totalServers: json.totalServers,
                            totalTokens: json.totalTokens
                        }
                    });
                    console.log(data);
                }
            }
        }
        
        const intervalId = setInterval(async () => {
            managerEffect();
        }, 2 * 1000);

        managerEffect();
        
        return () => {
			clearInterval(intervalId);
		};
    }, [data.refresh])



    return (
        <div className='row'>
            <div className='col-xl-12'>
                <div className="row">
                    <Tabs />
                    <div className="col-lg-8">
                        <div className="card">
                            <div className="card-body">
                                <Search/>
                            </div>
                        </div>
                        <div className="">
                            <div className="table-responsive">
                                <table className="table project-list-table table-nowrap align-middle table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <div className="form-check form-check-primary font-size-16">
                                                    <input className="form-check-input" type="checkbox" onChange={(event) => { handleCheckboxALL() }} />
                                                </div>
                                            </th>
                                            <th scope="col" style={{ textAlign: 'center', verticalAlign: 'middle' }}>Status</th>
                                            <th scope="col">Desktop Name</th>
                                            <th scope="col">Last Update</th>
                                            <th scope="col" style={{ textAlign: 'center', verticalAlign: 'middle' }}>EarnApp</th>
                                            <th scope="col">Token</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <Tbody/>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Server;