import React, { useEffect, useState } from 'react'

import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UsersList from './container/users.js';
import TokensList from './container/tokens.js';
import ServersList from './container/servers.js';
library.add(fas)

function Admin () {
    const [newsTab, setNewsTab] = useState(1)

    const newsTabClick = str => {
        setNewsTab(str)
    }
    return (
    <div>

        <div className='row'>
            <div className='col-xl-12'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='row'>
                            <ul class='nav nav-pills nav-justified' role='tablist'>
                                <li class='nav-item waves-effect waves-light' role='presentation' >
                                    <button type='button' className={newsTab == 1 ? 'nav-link active' : 'nav-link'} style={ newsTab == 1 ? { backgroundColor: '#8f5fe8', color: '#fff' } : {backgroundColor: '#343a40', color: '#fff'} }  onClick={() => newsTabClick(1)} >
                                    <span class='d-block d-sm-none'>
                                        <FontAwesomeIcon icon="fa-solid fa-home" />
                                    </span>
                                    <span class='d-none d-sm-block'>Users</span>
                                    </button>
                                </li>

                                <li class='nav-item waves-effect waves-light' role='presentation' >
                                    <button type='button' className={newsTab == 2 ? 'nav-link active' : 'nav-link'} style={ newsTab == 2 ? { backgroundColor: '#8f5fe8', color: '#fff' } : {backgroundColor: '#343a40', color: '#fff'} } onClick={() => newsTabClick(2)} >
                                    <span class='d-block d-sm-none'>
                                        <FontAwesomeIcon icon="fa-solid fa-hammer" />
                                    </span>
                                    <span class='d-none d-sm-block'>Tokens</span>
                                    </button>
                                </li>

                                <li class='nav-item waves-effect waves-light' role='presentation' >
                                    <button type='button' className={newsTab == 3 ? 'nav-link active' : 'nav-link'} style={ newsTab == 3 ? { backgroundColor: '#8f5fe8', color: '#fff' } : {backgroundColor: '#343a40', color: '#fff'} } onClick={() => newsTabClick(3)} >
                                    <span class='d-block d-sm-none'>
                                        <FontAwesomeIcon icon="fa-solid fa-hammer" />
                                    </span>
                                    <span class='d-none d-sm-block'>Servers</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        <div>
            {newsTab == 1 ? (
                <UsersList />
            ) : newsTab == 2 ? (
                <TokensList />
            ) : newsTab == 3 ? (
                <ServersList />
            ) : null}
            </div>
        </div>
    </div>
  )
}

export default Admin
