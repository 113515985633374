import React, { useEffect, useState } from 'react'

import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function News () {

    const [news, setNews] = useState("");

    useEffect(() => {
        const newsEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/panel/news',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('token')
                    }
                }
            )
            const data = await response.json()
            if (response.ok) {
                setNews(data.data);
            }
        }

        newsEffect();
    }, [])

    return (
        <>
            <pre
                style={{
                    color: 'white',
                    overflowX: 'hidden',
                    whiteSpace: 'normal'
                }}
                        
                dangerouslySetInnerHTML={{
                    __html: news
                }}
            ></pre>
        </>
    )
}

export default News;