import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {QRCodeSVG} from 'qrcode.react';
import { useCopyToClipboard } from "@uidotdev/usehooks";
library.add(fas)

function SecondPage () {
    const navigate = useNavigate();
    const [copiedText, copyClipboard] = useCopyToClipboard();
    const [countDown, setCountDown] = useState(3599);

    const [data, setData] = useState({
        selectedCurrency: 'bitcoin',
        buttonPayment:{
            1: 'Proceed to Payment',
            2: 'I Have Sent !',
            3: 'Check payment status'
        },
        buttonIcon: {
            1: 'fa-solid fa-paper-plane',
            2: 'fa-solid fa-check',
            3: 'fa-solid fa-rotate-right',
        },
        createPayment: false,
        countDown: 3599,
        availableCurrency: {},
        availableStatus:{
            PENDING: 'f1b44c',
            COMPLETED: '34c38f',
            PARTIAL: '556ee6',
            VOIDED: 'f46a6a'
        },
        ratelimite: false,
        step: 1,
        paymentInfo: {}
    });

    const setCurrencyButton = (current) => {
        setData({
            ...data,
            selectedCurrency: current
        });
    }

    const setTime = (current) => {
        setCountDown(current);
    }

    const setStepButton = () => {
        setData({
            ...data,
            step: ++data.step,
        });
    }

    const copyToClipboard = (text) => {
        copyClipboard(text);
        toast('Successful copying to clipboard !', {
            icon: '👏',
            style: {
                borderRadius: '10px',
                background: '#32394e',
                color: '#fff',
            },
        });
    };
    

    useEffect(() => {
        let intervalId;

        if (data.createPayment == true && countDown > 0) {
            intervalId = setInterval(() => {
                setTime(countDown - 1);
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [data.createPayment, countDown]);

    useEffect(() => {
        const cryptoListEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/premium/list',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('token')
                    }
                }
            )

            const reponseJson = await response.json()
            if (response.ok) {
                setData({
                    ...data,
                    availableCurrency: reponseJson.data
                });
            }
        }

        const cryptoPaymentEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/premium/create',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('token')
                    },
                    body: JSON.stringify({
                        gateway: data.selectedCurrency
                    })
                }
            )

            const reponseJson = await response.json()
            if (response.ok) {
                setData({
                    ...data,
                    paymentInfo: reponseJson.data,
                    createPayment: true
                });
            }
        }

        if(data.step === 1){
            cryptoListEffect();
        }else if(data.step === 2){
            if(data.createPayment == false){ 
                cryptoPaymentEffect();
            }
        }

    }, [data.step, data.ratelimite])

    useEffect(() => {
        console.log(data.ratelimite)
    }, [data]);

    const checkPaymentStatus = async () => {
        const response = await fetch(
            'https://api.vnetwork.cc/api/v1/premium/check/' + data.paymentInfo.paymentKey,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: localStorage.getItem('token')
                }
            }
        )

        const reponseJson = await response.json();
        
        if(reponseJson.code == '200'){
            setData({
                ...data,
                ratelimite: true,
                paymentInfo:{
                    ...data.paymentInfo,
                    cryptoStatus: reponseJson.data.cryptoStatus
                }
            });
            setTimeout(() => {
                setData({
                    ...data,
                    ratelimite: false
                });
            }, 120000);
        }else if(reponseJson.code == '429'){
            toast('You are ratelimited !', {
                icon: '😡',
                style: {
                    borderRadius: '10px',
                    background: '#32394e',
                    color: '#fff',
                },
            });
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    return (
        <div className="checkout-tabs" data-select2-id="16">
            <div className="row" data-select2-id="15">
                <div className="col-xl-2 col-sm-3">
                    <div className="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                        <a className={`nav-link ${data.step === 1 ? "active": ""}`}>
                            <i className="bx bxs-truck d-block check-nav-icon mt-4 mb-2"></i>
                            <p className="fw-bold mb-4">Create Payment</p>
                        </a>
                        <a className={`nav-link ${data.step === 2 ? "active": ""}`}>
                            <i className="bx bx-money d-block check-nav-icon mt-4 mb-2"></i>
                            <p className="fw-bold mb-4">Payment</p>
                        </a>
                        <a className={`nav-link ${data.step === 3 ? "active": ""}`}>
                            <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2"></i>
                            <p className="fw-bold mb-4">Confirmation</p>
                        </a>
                    </div>
                </div>
                <div className="col-xl-10 col-sm-9" data-select2-id="14">
                    <div className="card" data-select2-id="13">
                        <div className="card-body" data-select2-id="12">
                            <div className="tab-content">
                                <div className="tab-pane fade active show" id="v-pills-confir" role="tabpanel" aria-labelledby="v-pills-confir-tab">
                                    <div className="card shadow-none border mb-0">
                                        <div className="card-body">
                                            {data.step === 1 && (
                                                <div>
                                                    <h4 className="card-title mb-4">Create Payment</h4>
                                                    <div className="mb-2">
                                                        <label>Select your Currency :</label>
                                                        <div className="row">
                                                            { data.availableCurrency.length >= 1 && data.availableCurrency.map((list) => { 
                                                                return (
                                                                    <div className="col-xl-3 col-sm-6 ">
                                                                        <div className="card" style={{cursor: 'pointer'}} onClick={() => {setCurrencyButton(list.gatewayName)}}>
                                                                            <div className={`card-header text-uppercase bg-light ${data.selectedCurrency == list.gatewayName ? 'border border-primary' : ''}`}>
                                                                                <div className="d-flex flex-row bg-light justify-content-center">
                                                                                    <img src={list.gatewayImage} className="avatar-sm rounded me-1" />
                                                                                    <h5 style={{ marginTop: '1em' }}>{list.gatewayName}</h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {data.step === 2 && (
                                                <div>
                                                    <h4 className="card-title mb-4">Payment</h4>
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <p>You can click on Crypto Address & Crypto Amount to copy it to the clipboard, <br></br>use crypto amount when you want to send to make sure you don't have any payment problems</p>
                                                            <div class="row align-items-stretch">
                                                                <div class="col-md-8 mb-3">
                                                                    <div class="bg-light h-100 p-3 d-flex flex-column align-items-stretch mb-3">
                                                                        <div class="table-responsive" style={{with:'20px' }}>
                                                                            <table class="table">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th scope="col">Gateway :</th>
                                                                                        <td scope="col" >{data.paymentInfo.gateway} </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th scope="col">Status :</th>
                                                                                        <td scope="col" style={{color: '#'+data.availableStatus[data.paymentInfo.cryptoStatus]}}>{data.paymentInfo.cryptoStatus} </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th scope="col">Total :</th>
                                                                                        <td scope="col">{data.paymentInfo.total} {data.paymentInfo.currency}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th scope="col">Crypto Amount :</th>
                                                                                        <td scope="col" onClick={() => {copyToClipboard(data.paymentInfo.cryptoAmount)}} style={{color: '#'+data.paymentInfo.color, cursor: 'pointer'}}>{data.paymentInfo.cryptoAmount} {data.paymentInfo.gatewayName}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th scope="col">Crypto Address :</th>
                                                                                        <td scope="col" onClick={() => {copyToClipboard(data.paymentInfo.cryptoAddress)}} style={{cursor: 'pointer', wordWrap: "break-word", overflowWrap: "break-word"}}>{data.paymentInfo.cryptoAddress }</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th scope="col">Cancelled in :</th>
                                                                                        <td scope="col">{formatTime(data.countDown)}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-4 mb-3'>
                                                                    <div class=" h-100 d-flex align-items-center justify-content-center p-3">
                                                                        <div className='row justify-content-center'>
                                                                            <div class="col-xl-12 text-center">
                                                                                
                                                                                {data.paymentInfo && data.paymentInfo.cryptoUri && (
                                                                                    <div className='d-flex justify-content-center align-items-center'>
                                                                                        <QRCodeSVG height='80%' width="80%"  value={data.paymentInfo.cryptoUri} className="text-center" style={{ background: 'white', padding: '5px', textAlign: 'center' }} />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            )}

                                            {data.step === 3 && (
                                                <div>
                                                    <h4 className="card-title mb-4">Waiting for the payment ...</h4>
                                                    <div className='row justify-content-center'>
                                                        <div class="col-xl-12 text-center">
                                                            <div class="table-responsive" style={{with:'10px' }}>
                                                                <table class="table">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th scope="col">Gateway</th>
                                                                            <td scope="col">{data.paymentInfo.gateway} </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="col">Total</th>
                                                                            <td scope="col">{data.paymentInfo.total} {data.paymentInfo.currency}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="col">Crypto Amount</th>
                                                                            <td scope="col" onClick={() => {copyToClipboard(data.paymentInfo.cryptoAmount)}} style={{color: '#'+data.paymentInfo.color, cursor: 'pointer'}}>{data.paymentInfo.cryptoAmount} {data.paymentInfo.gatewayName}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="col">Crypto Address</th>
                                                                            <td scope="col" onClick={() => {copyToClipboard(data.paymentInfo.cryptoAddress)}} style={{cursor: 'pointer'}}>{data.paymentInfo.cryptoAddress}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="col">Payment status</th>
                                                                            <td scope="col" style={{color: '#'+data.availableStatus[data.paymentInfo.cryptoStatus]}}>{data.paymentInfo.cryptoStatus}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th scope="col">Cancelled in</th>
                                                                            <td scope="col">{formatTime(countDown)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <p className='text-danger'>Once the payment and the crypto confirmations are done, you will have directly the add to your account, this can take from 30 minutes to 1 hour. </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-sm-6">
                            <a onClick={() => {navigate('/premium')}} className="btn text-muted d-none d-sm-inline-block btn-link"> <i className="mdi mdi-arrow-left me-1"></i> Back to Premium Page </a>
                        </div> 
                        {(data.step == 1 || data.step == 2 || data.step == 3) && (
                            <div className="col-sm-6">
                                <div className="text-end">
                                    <button onClick={() => {
                                        data.step == 3 ? checkPaymentStatus() : setStepButton()
                                    }} className="btn btn-success" disabled={data.ratelimite == true ? true : false}> <FontAwesomeIcon icon={data.buttonIcon[data.step]} /> {data.buttonPayment[data.step]} </button>
                                </div>
                            </div>
                        )}
                    </div> 
                </div>
            </div>
        </div>

    )
}

export default SecondPage;