import React, { useEffect, useState, useRef  } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import toast from 'react-hot-toast';
import FirstPage from './container/firstPage.js';
import SecondPage from './container/secondPage.js';
library.add(fas)

function Premium () {
    const [premium, setPremium] = useState(true);
    const navigate = useNavigate()
    const { id } = useParams();



    useEffect(() => {
        const profileEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/user/@me',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('token')
                    }
                }
            )

            const data = await response.json()
            if (response.ok) {
                /*if(data && data.user && data.user.premium != true){
                    setPremium(false);
                }*/
            }
        }

        profileEffect();

    }, [])

    return (
    <div>
        {!id && (
            <FirstPage />
        )}

        {id && (id == "payment") && (
            <SecondPage />
        )}
    </div>
    )
}

export default Premium
