import React, { useEffect, useState } from 'react'

import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Builder from './container/builder.js'
import Statistique from './container/statistique.js'
import Profile from './container/profile.js';
import LastServer from './container/last-server.js';
import News from './container/news.js';
library.add(fas)

function Dashboard () {
  const [newsTab, setNewsTab] = useState(true)

  const newsTabClick = boolStr => {
    setNewsTab(!boolStr)
  }

  return (
    <div>
      <Statistique />
      <div className='row'>
        <div className='col-xl-4'>
          <Profile />
          <LastServer />
        </div>
        <div className='col-xl-8'>
          <div className='card'>
            <div className='card-body'>
              <div className='row'>
                <ul class='nav nav-pills nav-justified' role='tablist'>
                  <li class='nav-item waves-effect waves-light' role='presentation' >
                    <button type='button' className={newsTab ? 'nav-link active' : 'nav-link'} style={ newsTab ? {} : { backgroundColor: '#343a40', color: '#fff' } }  onClick={() => newsTabClick(false)} >
                      <span class='d-block d-sm-none'>
                        <FontAwesomeIcon icon="fa-solid fa-home" />
                      </span>
                      <span class='d-none d-sm-block'>News</span>
                    </button>
                  </li>

                  <li class='nav-item waves-effect waves-light' role='presentation' >
                    <button type='button' className={newsTab ? 'nav-link ' : 'nav-link active'} style={ newsTab ? { backgroundColor: '#343a40', color: '#fff' } : {} } onClick={() => newsTabClick(true)} >
                      <span class='d-block d-sm-none'>
                        <FontAwesomeIcon icon="fa-solid fa-hammer" />
                      </span>
                      <span class='d-none d-sm-block'>Builder</span>
                    </button>
                  </li>
                </ul>

                <div class='tab-content p-3 text-muted'>
                  <div class='tab-pane active' id='home-1' role='tabpanel'>
                    {newsTab ? (
                      <News />
                    ) : (
                      <Builder />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
