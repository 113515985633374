import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css' // Ensure you import the styles for Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import './assets/css/app.min.css'
import './assets/css/icons.min.css'
import './assets/css/bootstrap.min.css'
import { Toaster } from 'react-hot-toast';

library.add(fas)
function AppIndex (props) {
  return (
    <div data-topbar='dark' data-layout='horizontal' data-layout-mode='dark'>
      <div id='layout-wrapper'>
        {props.component && (
        <header id='page-topbar'>
          <div className='navbar-header'>
            <div className='d-flex'>
              <div className='navbar-brand-box'>
                <a href='/' className='logo logo-light'>
                  <span className='logo-lg'>
                    <img
                      src='./assets/images/logo-light.png'
                      alt=''
                      style={{ height: '60px' }}
                    ></img>
                  </span>

                  <span className='logo-sm'>
                    <h3>
                      <b>VNETWORK.CC</b>
                    </h3>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </header>
        )}
        <div className='main-content'>
          <Toaster />
          {props.index && (
            props.index
          )}
          {props.component && (
            <div className='page-content'>
              <div className='container-fluid'>
                <div className='row justify-content-center'>
                  <div className='col-xl-5'>
                    <div className='card'>
                      <div className='card-body'>
                        {props.component}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='rightbar-overlay'></div>
      <script src='%PUBLIC_URL%/libs/jquery/jquery.min.js'></script>
      <script src='%PUBLIC_URL%/libs/bootstrap/js/bootstrap.bundle.min.js'></script>
      <script src='%PUBLIC_URL%/libs/metismenu/metisMenu.min.js'></script>
      <script src='%PUBLIC_URL%/libs/simplebar/simplebar.min.js'></script>
      <script src='%PUBLIC_URL%/assets/libs/node-waves/waves.min.js'></script>
      <script src='%PUBLIC_URL%/assets/libs/apexcharts/apexcharts.min.js'></script>

      <script src='%PUBLIC_URL%/assets/js/pages/dashboard.init.js'></script>
    </div>
  )
}

export default AppIndex
