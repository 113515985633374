import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function FirstPage () {
    const navigate = useNavigate();


    return (
        <div className="row">
            <div className="col-xl-3">
                <div className="card">
                    <div className="card-body">
                        <div className="text-center fw-semibold">
                            <h5 className="mb-1">Overview</h5>
                            <p className="text-muted mb-3">Premium Vnetwork</p>
                        </div>

                        <div className="table-responsive">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th scope="col">Price</th>
                                        <td scope="col">15 $</td>
                                    </tr>
                                    <tr>
                                        <th scope="col">For how long?</th>
                                        <td scope="col">3 months</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-1">
                            <a onClick={() => {navigate('/premium/payment') }} className="btn btn-soft-primary btn-hover w-100 rounded">BUY</a>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <div className="d-flex flex-wrap text-center" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    
                            <img width="48" height="48" src="https://img.icons8.com/fluency/48/bitcoin.png" alt="bitcoin"/>
                            <img width="48" height="48" src="https://img.icons8.com/fluency/48/ethereum.png" alt="ethereum"/>
                            <img width="48" height="48" src="https://img.icons8.com/fluency/48/litecoin.png" alt="litecoin"/>
                            <img width="48" height="48" src="https://img.icons8.com/fluency/48/monero.png" alt="monero"/>
                            <img width="48" height="48" src="https://img.icons8.com/fluency/48/paypal.png" alt="paypal"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-9">
                <div className="card">
                    <div className="card-body border-bottom">
                        <div className="d-flex">
                            <div className="flex-grow-1 ms-3">
                                <h5 className="fw-semibold">Description</h5>
                                <ul className="list-unstyled hstack gap-2 mb-0">
                                    <li>
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" /> <span className="text-muted">By paying you would have access to</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div className="flex-grow-1 ms-3">
                            <h5 class="fw-semibold mb-3">The possibility of :</h5>
                        </div>
                        <ul class="vstack gap-3">
                            <li>
                                <FontAwesomeIcon icon="fa-solid fa-right-long" className="text-success" /> Access to executable creation area
                            </li>
                            <li>
                                <FontAwesomeIcon icon="fa-solid fa-right-long" className="text-success"/> Create and customize your executable 
                            </li>
                            <li>
                                <FontAwesomeIcon icon="fa-solid fa-right-long" className="text-success"/> Manager/Manage access
                            </li>
                            <li>
                                <FontAwesomeIcon icon="fa-solid fa-right-long" className="text-success"/> See the machines in real time
                            </li>
                            <li>
                                <FontAwesomeIcon icon="fa-solid fa-right-long" className="text-success"/> View machine information (ip, ram, mac address, Uuid, os, gpu, cpu)
                            </li>
                            <li>
                                <FontAwesomeIcon icon="fa-solid fa-right-long" className="text-success"/> See the machine's discord token
                            </li>
                            <li>
                                <FontAwesomeIcon icon="fa-solid fa-right-long" className="text-success"/> View the machine's browser passwords
                            </li>
                            <li>
                                <FontAwesomeIcon icon="fa-solid fa-right-long" className="text-success"/> View the machine's browser cookies
                            </li>
                            <li>
                                <FontAwesomeIcon icon="fa-solid fa-right-long" className="text-success"/> execute remote commands and see the result if there is one
                            </li>
                            <li>
                                <FontAwesomeIcon icon="fa-solid fa-right-long" className="text-success"/> get future updates
                            </li>
                            <li>
                                <FontAwesomeIcon icon="fa-solid fa-right-long" className="text-success"/> 24/7 premium support
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FirstPage;