import React, { useEffect, useState, useRef } from 'react'
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function Console () {
    const [consoleData, setConsoleData] = useState({});
    const { id } = useParams()
    const [console, setConsole] = useState('')
    const consoleInputRef = useRef(null);

    const consoleHandler = event => {
        setConsole(event.target.value)
    }



    const handleKeyPress = (event) => {
        if (event.keyCode === 13) {
            consoleEffect();
        }
    };

    const consoleEffect = async () => {
        if(console == ""){
            toast('You have not set a command !', {
                icon: '😡',
                style: {
                    borderRadius: '10px',
                    background: '#32394e',
                    color: '#fff',
                },
            });
        }else{
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/manage/' + id + '/console',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('token')
                    },
                    body: JSON.stringify({
                        console: console
                    })
                }
            )

            const data = await response.json()
            if (response.ok) {

                if(data && data.code == 200){
                    toast('Your order has been sent to the server !', {
                        icon: '👏',
                        style: {
                            borderRadius: '10px',
                            background: '#32394e',
                            color: '#fff',
                        },
                    });

                    setConsole('');
                    if (consoleInputRef.current) {
                        consoleInputRef.current.value = '';
                    }
                }
            }

            if(response.status == 400){
                if(data && data.code == 400){
                    if(data.state == 1){
                        toast('You have not set a command !', {
                            icon: '😡',
                            style: {
                                borderRadius: '10px',
                                background: '#32394e',
                                color: '#fff',
                            },
                        });
                    }

                    if(data.state == 2){
                        toast('You cannot send a command to a disconnected server !', {
                            icon: '😡',
                            style: {
                                borderRadius: '10px',
                                background: '#32394e',
                                color: '#fff',
                            },
                        });
                    }
                }
            }
            
        }
    }

    useEffect(() => {


        const serverEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/manage/' + id + '/console',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        authorization: localStorage.getItem('token')
                    }
                }
            )

            const data = await response.json()
            if (response.ok) {
                setConsoleData(data);
            }
        }
        const intervalId = setInterval(async () => {
            serverEffect();
        }, 1000);

        serverEffect();

        const consoleCommandElement = consoleInputRef.current;
        consoleCommandElement.addEventListener('keypress', handleKeyPress);
        
        return () => {
            consoleCommandElement.removeEventListener('keypress', handleKeyPress);
			clearInterval(intervalId);
		};
    }, [console])

    return (
        <div >
            <textarea className="form-control" style={{backgroundColor: "#000000"}} rows="25" disabled="" readOnly value={`${(consoleData.console) ? consoleData.console : "Microsoft Windows\n(c) Microsoft Corporation. All rights reserved."}`}></textarea>
            <div className='input-group mb-1'>
                <textarea  ref={consoleInputRef} className="form-control" onChange={consoleHandler} style={{backgroundColor: "#000000", border:"2px solid #222736"}} rows="1">{console}</textarea>
                <button  onClick={() => consoleEffect()} type="button" class="btn btn-primary waves-effect waves-light"><FontAwesomeIcon icon="fa-solid fa-paper-plane" /> Send</button>
            </div>
        </div>
    )
}

export default Console;