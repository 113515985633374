import React, { useEffect, useState } from 'react'

import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function Statistique () {

    const [statistique, setStatistique] = useState({});
    
    useEffect(() => {
        const statistiqueEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/panel/statistique',
                {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: localStorage.getItem('token')
                }
                }
            )
            const data = await response.json()
            if (response.ok) {
                setStatistique(data);
            }
        }

        statistiqueEffect();

    }, [])

    return (
        <div className='row'>
            <div className='col-md-4'>
                <div className='card mini-stats-wid'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            <div className='flex-grow-1 placeholder-glow'>
                                <p className='text-muted fw-medium'>Total User</p>
                                <h4 className={`mb-0 ${statistique.totalUser ? '':'placeholder'}`}>{statistique.totalUser ? statistique.totalUser : '0000000'}</h4>
                            </div>

                            <div className='flex-shrink-0 align-self-center'>
                                <div className='mini-stat-icon avatar-sm rounded-circle bg-primary'>
                                    <span className='avatar-title'>
                                        <FontAwesomeIcon icon='fa-solid fa-users' />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='card mini-stats-wid'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            <div className='flex-grow-1 placeholder-glow'>
                                <p className='text-muted fw-medium'>User Managed</p>
                                <h4 className={`mb-0 ${statistique.totalUserManaged ? '':'placeholder'}`}>{statistique.totalUserManaged ? statistique.totalUserManaged : '00000'}</h4>
                            </div>

                            <div className='flex-shrink-0 align-self-center'>
                                <div className='mini-stat-icon avatar-sm rounded-circle bg-primary'>
                                    <span className='avatar-title'>
                                        <FontAwesomeIcon icon='fa-solid fa-server' />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='card mini-stats-wid'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            <div className='flex-grow-1 placeholder-glow'>
                                <p className='text-muted fw-medium'>Total Token</p>
                                <h4 className={`mb-0 ${statistique.totalToken ? '':'placeholder'}`}>{statistique.totalToken ? statistique.totalToken : '00000'}</h4>
                            </div>

                            <div className='flex-shrink-0 align-self-center'>
                                <div className='mini-stat-icon avatar-sm rounded-circle bg-primary'>
                                    <span className='avatar-title'>
                                        <FontAwesomeIcon icon='fa-solid fa-cloud' />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Statistique;