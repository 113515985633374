import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function NotFound () {
  return (
    <div>
        <div className='mb-3'>
            <div className="text-center mb-5">
                <h1 className="display-2 fw-medium">4<i className="bx bx-buoy bx-spin text-primary display-3"></i>4</h1>
                <h4 className="text-uppercase">Sorry, page not found</h4>
                <div className="mt-5 text-center">
                    <a className="btn btn-primary waves-effect waves-light" href="/">Back to safety</a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NotFound
