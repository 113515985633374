import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function TokensList () {
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState({state: 0});
    const [premiumState, setPremiumState] = useState("0");
    useEffect(() => {
        const serverEffect = async () => {
            try {
                const response = await fetch(
                    'https://api.vnetwork.cc/api/v1/admin/tokens',
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': localStorage.getItem('token')
                        }
                    }
                );
                if (response.ok) {
                    const data = await response.json();
                    setUsers(data.data);
                } else {
                    // Handle error response
                }
            } catch (error) {
                // Handle fetch error
            }
        }

        const intervalId = setInterval(async () => {
            serverEffect();
        }, 2000);

        serverEffect();

        return () => {
			clearInterval(intervalId);
		};
    }, [])

    const ChangePremium = async (id) => {
        try {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/admin/members/' + id,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem('token')
                    },
                    body: JSON.stringify({
                        premium: premiumState
                    }),
                }
            );
            if (response.ok) {
                toast('The premium was successfully changed !', {
                    icon: '👏',
                    style: {
                        borderRadius: '10px',
                        background: '#32394e',
                        color: '#fff',
                    },
                });
            } else {

            }
        } catch (error) {
        }
    }

    const ChangeApiKey = async (id) => {
        try {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/admin/members/' + id,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': localStorage.getItem('token')
                    },
                    body: JSON.stringify({
                        apiKey: 'change'
                    }),
                }
            );
            if (response.ok) {
                toast('The Api Key was successfully changed !', {
                    icon: '👏',
                    style: {
                        borderRadius: '10px',
                        background: '#32394e',
                        color: '#fff',
                    },
                });
            } else {

            }
        } catch (error) {
        }
    }

    const ChangeEffect = async (id, username, apiKey, state) => {
        setUser({
            state: state,
            username: username,
            id: id,
            apiKey: apiKey
        })
    }

    const formatUptime = (timestamp) => {
        const currentTime = Math.floor(Date.now() / 1000);
        const durationInSeconds = currentTime - timestamp;

        const months = Math.floor(durationInSeconds / (30 * 24 * 60 * 60));
        const days = Math.floor((durationInSeconds % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
        const hours = Math.floor((durationInSeconds % (24 * 60 * 60)) / (60 * 60));
        const minutes = Math.floor((durationInSeconds % (60 * 60)) / 60);
        const seconds = Math.floor(durationInSeconds % 60);

        const parts = [];
        if (months > 0) parts.push(`${months} month`);
        if (days > 0) parts.push(`${days} days`);
        if (hours > 0) parts.push(`${hours} hours`);
        if (minutes > 0) parts.push(`${minutes} minutes`);

        return parts.join(', ') || 'Less than a second';
    }

    const formatPremium = (timestamp) => {
        var date = new Date(timestamp * 1000);

        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    return (
        <>
            <div className='row'>
                <div className='col-xl-12'>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="table-responsive">
                                    <table className="table project-list-table table-nowrap align-middle table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Username</th>
                                                <th scope="col">Token</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Mail</th>
                                                <th scope="col">premium Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map((server, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <h5 className="text-truncate font-size-14 text-uppercase">{server.username}</h5>
                                                        <p className="text-muted mb-0">{server.tId}</p>
                                                    </td>
                                                    <td>{server.token}</td>
                                                    <td> {server.phone} </td>
                                                    <td> {server.email} </td>
                                                    <td> {server.premiumType}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-modal="true" style={user.state == 1 ? {display:'block'} : {}}>
                <div class="modal-dialog ">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">{user.username}</h5>
                            <button type="button" class="btn-close" onClick={() => { ChangeEffect(null, null, null, 0)}} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <label>Premium {premiumState}</label>
                            <div class="d-flex flex-row justify-content-between">
                                <div class="input-group mb-1">
                                    <select class="form-control" style={{width:'20px'}} onChange={(choice) => setPremiumState(choice.target.value)}>
                                        <option value="0">Remove Premium</option>
                                        <option value="1">1 month</option>
                                        <option value="2">3 month</option>
                                        <option value="3">6 month</option>
                                        <option value="4">12 month</option>

                                    </select>
                                    <button type="button" onClick={() => {ChangePremium(user.id)}} class="btn btn-outline-primary waves-effect"> Set</button>
                                </div>
                            </div><br></br>
                            <label>Api-Key</label>
                            <div class="d-flex flex-row justify-content-between">
                                <div class="input-group mb-1">
                                    <input class="form-control" type="text" value={user.apiKey} id="example-text-input" />
                                    <button type="button" class="btn btn-outline-primary waves-effect" onClick={() => {ChangeApiKey(user.id)}}> Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TokensList;