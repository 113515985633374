import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(fas)

function LastServer () {
    const navigate = useNavigate()
    const [lastServer, setlastServer] = useState([]);
    const [premium, setPremium] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const lastServerEffect = async () => {
            const response = await fetch(
                'https://api.vnetwork.cc/api/v1/panel/last-server',
                {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: localStorage.getItem('token')
                }
                }
            )
            const data = await response.json()
            if (response.ok) {
                setlastServer(data.data);
                setPremium(data.premium);
                setLoading(false);
            }

            if(response.status == '401' && data.premium == false){
                setPremium(data.premium);
                setlastServer({});
                setLoading(false);
            }
        }
        
        const intervalId = setInterval(async () => {
            lastServerEffect();
        }, 5000);

        lastServerEffect();

        return () => {
			clearInterval(intervalId);
		};
    }, [])

    return (
        <div className='card'>
            <div className='card-body'>
                <h4 className='card-title mb-4'>Last User Managed</h4>
        
                <div className='row'>
                    <div className='col-sm-12'>
                        {loading == true && (
                            <>
                                <div className="bg-light p-3 d-flex mb-3 rounded managed placeholder-glow"> 
                                    <a className="avatar-sm rounded me-3">
                                        <div className="border rounded avatar-sm">
                                            <span className="avatar-title bg-transparent placeholder">
                                            </span>
                                        </div>
                                    </a>
                                    <div className="flex-grow-1">
                                        <h5 className="font-size-15 mb-2 placeholder">000000000000000000000000000000000000</h5><br />
                                        <h5 className="font-size-15 mb-2 placeholder">000000000000000</h5>
                                    </div>
                                </div>
                                <div className="bg-light p-3 d-flex mb-3 rounded managed placeholder-glow"> 
                                    <a className="avatar-sm rounded me-3">
                                        <div className="border rounded avatar-sm">
                                            <span className="avatar-title bg-transparent placeholder">
                                            </span>
                                        </div>
                                    </a>
                                    <div className="flex-grow-1">
                                        <h5 className="font-size-15 mb-2 placeholder">0000000000000000000000000000000</h5><br />
                                        <h5 className="font-size-15 mb-2 placeholder">00000000000000000</h5>
                                    </div>
                                </div>
                            </>
                        )}

                        {loading == false && premium == false && (
                            <div className="bg-light p-3 d-flex mb-3 rounded managed" onClick={() => {navigate(`/premium`);}}>
                                <div className="flex-grow-1">
                                    <h5 className="font-size-15 mb-1 text-center">❌ {premium} You are not premium ❌</h5>
                                </div>
                            </div>
                        )}
                        {loading == false && premium == true && lastServer.length >= 1 && lastServer.map((server) => {return (
                            <div className="bg-light p-3 d-flex mb-3 rounded managed" onClick={() => {navigate(`/manage/${server.serverId}`);}}> 
                                <a className="avatar-sm rounded me-3">
                                    <div className="border rounded avatar-sm">
                                        <span className="avatar-title bg-transparent">
                                            {server.utime > Math.floor(Date.now() / 1000) - 60 ? (
                                                <FontAwesomeIcon style={{fontSize: '1.5em'}} className={`fa-solid fa-rss text-success`} icon='fa-solid fa-wheelchair-move' />
                                            ) : (
                                                <FontAwesomeIcon style={{fontSize: '1.5em'}} className={`fa-solid fa-rss text-danger`} icon='fa-solid fa-bed' />
                                            )}
                                        </span>
                                    </div>
                                </a>
                                <div className="flex-grow-1">
                                    <h5 className="font-size-15 mb-2">{server.desktopName} <span className="badge badge-soft-info">{server.ip}</span></h5>
                                    <p className="mb-0 text-muted">  {server.os} </p>
                                </div>
                            </div>
                        )})}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LastServer;