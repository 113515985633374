import React, { useEffect, useState, useContext, } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import {useDataContext} from '../../panel.js';
library.add(fas)

function Search () {
    const { data, setData } = useDataContext();

    const viewHandle = () => {
        if(data.view == "all"){
            setData({
                ...data,
                view: "connected",
                refresh: Math.random()
            });
        }else if(data.view == "connected"){
            setData({
                ...data,
                view: "disconnected",
                refresh: Math.random()
            });
        }else if(data.view == "disconnected"){
            setData({
                ...data,
                view: "all",
                refresh: Math.random()
            });
        }
    }

    const searchHandle = event => {
        setData({
            ...data,
            search: event.target.value,
            refresh: Math.random()
        });
    }


    return (
        <>
            <div className="d-flex flex-row justify-content-between">
                <div className="input-group mb-1">
                    {data.view === "all" && (
                        <button type="button" className="btn btn-outline-secondary waves-effect" onClick={() => { viewHandle() }}>
                            <FontAwesomeIcon icon="fa-solid fa-eye" /> All
                        </button>
                    )}
                    {data.view === "connected" && (
                        <button type="button" className="btn btn-outline-success waves-effect" onClick={() => { viewHandle() }}>
                            <FontAwesomeIcon icon="fa-solid fa-eye" /> Connected
                        </button>
                    )}
                    {data.view === "disconnected" && (
                        <button type="button" className="btn btn-outline-danger waves-effect" onClick={() => { viewHandle() }}>
                            <FontAwesomeIcon icon="fa-solid fa-eye" /> Disconnected
                        </button>
                    )}
                    <input className="form-control" type="text" style={{ width: '20px' }} placeholder='Search' value={data.search} onChange={searchHandle}></input>
                </div>
            </div>
        </>
    )
}

export default Search;