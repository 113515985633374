import React, { useEffect, useState, useContext, } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import {useDataContext} from '../../panel.js';
library.add(fas)

function Tabs () {
    const { data, setData } = useDataContext();
    const [tabs, setTabs] = useState(0);

    const earnAppPut = async (action) => {
        const response = await fetch(
            'https://api.vnetwork.cc/api/v1/manager/earn-app',
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: localStorage.getItem('token')
                },
                body: JSON.stringify({
                    'servers': data.checkedServer,
                    'status': action
                })
            }
        )
        const json = await response.json()
        if (response.ok) {
            if(json.code == 200){
                setData({
                    ...data,
                    refresh: Math.random()
                });
                toast('Successful !', {
                    icon: '👏',
                    style: {
                        borderRadius: '10px',
                        background: '#32394e',
                        color: '#fff',
                    },
                });
            }else{
                toast('Error !', {
                    icon: '😡',
                    style: {
                        borderRadius: '10px',
                        background: '#3394e',
                        color: '#fff',
                    },
                });
            }
        }
    }

    const settabs = (id) => {
        setTabs(id);
    }

    return (
        <>
            <div class="col-lg-4">
                <div class="card">
                    <div class="card-body">
                        <ul class='nav nav-pills nav-justified' role='tablist'>
                            <li class='nav-item waves-effect waves-light' role='presentation' style={{marginRight: '10px', paddingRight:'19px'}}>
                                <button type='button' className={tabs == 0 ? 'nav-link active' : 'nav-link'} style={ tabs == 0 ? {} : { backgroundColor: '#343a40', color: '#fff' } }  onClick={() => {settabs(0)}} >
                                    <span class='d-block d-sm-none'>
                                        <FontAwesomeIcon icon="fa-solid fa-eye" />
                                    </span>
                                    <span class='d-none d-sm-block'>Overview</span>
                                </button>
                            </li>

                            <li class='nav-item waves-effect waves-light' role='presentation' style={{paddingRight:'19px'}}>
                                <button type='button' className={tabs == 1 ? 'nav-link active' : 'nav-link'} style={tabs == 1 ? {} : { backgroundColor: '#343a40', color: '#fff' } }  onClick={() => {settabs(1)}} >
                                    <span class='d-block d-sm-none'>
                                        <FontAwesomeIcon icon="fa-solid fa-money-bill" />
                                    </span>
                                    <span class='d-none d-sm-block'>Earn Money</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class='tab-content p-2 text-muted'>
                            <div class='tab-pane active' role='tabpanel'>
                                {tabs == 0 && (
                                    <div>
                                        <div class="text-center fw-semibold">
                                            <h5 class="mb-3">Overview</h5>
                                        </div>
                                        <div className='row'>
                                            <div class="col-lg-6">
                                                <div class="card bg-light border shadow-none mb-2">
                                                    <a class="text-body">
                                                        <div class="p-2">
                                                            <div class="d-flex">
                                                                <div class="avatar-xs align-self-center me-2">
                                                                    <div class="avatar-title rounded bg-transparent text-primary font-size-20"><i class="mdi mdi-server"></i></div>
                                                                </div>
                                                                <div class="overflow-hidden me-auto">
                                                                    <h5 class="font-size-13 text-truncate mb-1">Total Servers</h5>
                                                                    <p class="text-muted text-truncate mb-0">{data.statistics.totalServers} Server(s)</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="card bg-light border shadow-none mb-2">
                                                    <a class="text-body">
                                                        <div class="p-2">
                                                            <div class="d-flex">
                                                                <div class="avatar-xs align-self-center me-2">
                                                                    <div class="avatar-title rounded bg-transparent text-primary font-size-20"><i class="mdi mdi-account-group"></i></div>
                                                                </div>
                                                                <div class="overflow-hidden me-auto">
                                                                    <h5 class="font-size-13 text-truncate mb-1">Total Tokens</h5>
                                                                    <p class="text-muted text-truncate mb-0">{data.statistics.totalTokens} Token(s)</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )} 
                                {tabs == 1 && (
                                    <div>
                                        <div class="fw-semibold">
                                            <h5 class="mb-3 text-center">Earn Money</h5>
                                            <p>
                                                Welcome to the <b className='text-danger'>earn money tab</b>, here you can <b className='text-danger'>earn money</b> on your computer, and every time we get paid you will earn money, <b className='text-danger'>the more you have the more you will earn</b>, residential ip earn more money.<br/>
                                                to have that if you activate the options you will not be able to reach the machine it will <b className='text-danger'>deactivate</b> it to avoid problems
                                            </p>
                                        </div>
                                        <div className='row'>
                                            <div class="col-lg-6">
                                                <div class="card bg-light border shadow-none mb-2">
                                                    <a class="text-body">
                                                        <div class="p-2">
                                                            <div class="d-flex">
                                                                <div class="avatar-xs align-self-center me-2">
                                                                    <div class="avatar-title rounded bg-transparent text-primary font-size-20"><FontAwesomeIcon icon="fa-solid fa-sack-dollar" /></div>
                                                                </div>
                                                                <div class="overflow-hidden me-auto">
                                                                    <h5 class="font-size-13 text-truncate mb-1">Total Earn</h5>
                                                                    <p class="text-muted text-truncate mb-0">0$</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="card bg-light border shadow-none mb-2">
                                                    <a class="text-body">
                                                        <div class="p-2">
                                                            <div class="d-flex">
                                                                <div class="avatar-xs align-self-center me-2">
                                                                    <div class="avatar-title rounded bg-transparent text-primary font-size-20"><i class="mdi mdi-cash"></i></div>
                                                                </div>
                                                                <div class="overflow-hidden me-auto">
                                                                    <h5 class="font-size-13 text-truncate mb-1">This Month Earn</h5>
                                                                    <p class="text-muted text-truncate mb-0">0$</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="card bg-light border shadow-none mb-2">
                                                    <div class="p-2">
                                                        <div class="d-flex">
                                                            <div class="avatar-xs align-self-center me-2">
                                                                <div class="avatar-title rounded bg-transparent text-primary font-size-20"><i class="mdi mdi-server"></i></div>
                                                            </div>
                                                            <div class="overflow-hidden me-auto">
                                                                <h5 class="font-size-13 text-truncate mb-1">Total Devices</h5>
                                                                <p class="text-muted text-truncate mb-0">
                                                                {data.managerList.filter(server => {
                                                                    if(server.earnApp == 1){
                                                                        return server
                                                                    }
                                                                }).length} Device(s)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="card bg-light border shadow-none mb-2">
                                                    <div class="p-2">
                                                        <div class="d-flex">
                                                            <div class="avatar-xs align-self-center me-2">
                                                                <div class="avatar-title rounded bg-transparent text-primary font-size-20"><i class="mdi mdi-server"></i></div>
                                                            </div>
                                                            <div class="overflow-hidden me-auto">
                                                                <h5 class="font-size-13 text-truncate mb-1">Devices Connected</h5>
                                                                <p class="text-muted text-truncate mb-0">
                                                                {data.managerList.filter(server => {
                                                                    if(server.earnApp == 1 && server.time > Math.floor(Date.now() / 1000) - 60){
                                                                        return server
                                                                    }
                                                                }).length} Device(s)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><br></br>
                                        <p className='text-center text-danger'>Currently in development</p>
                                        <div class="hstack gap-2">
                                            <button class="btn btn-success w-100" onClick={() => {earnAppPut(1)}}>Active</button>
                                            <button class="btn btn-danger w-100" onClick={() => {earnAppPut(0)}}>Disable</button>
                                        </div>
                                    </div>
                                )}  
                            </div>
                        </div>
                    </div>
                </div>                      
            </div>

        </>
    )
}

export default Tabs;