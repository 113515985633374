import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import '@fortawesome/fontawesome-svg-core/styles.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavbarIndex from './components/general/navbarIndex.js'
library.add(fas)
function LandingPage () {
    const navigate = useNavigate()
    return (
        <div data-bs-spy="scroll" data-bs-target="#topnav-menu" data-bs-offset="60">
            <NavbarIndex />
            <section className="section hero-section bg-ico-hero" style={{backgroundImage: 'url(https://bafkreifvswtifkckmuszjivlsmkp6z7i5aphp5ronstubqd2sfjcvmibwy.ipfs.nftstorage.link/)'}}>
                <div className="container">
                    <div className="row align-items-center text-center">
                        <div className="col-lg-12">
                            <div className="text-white">
                                <h1 className="text-white fw-semibold mb-3 hero-title font-size-25">VNETWORK.CC</h1>
                                <p className="font-size-14 text-white">Centralize the management of all your Windows computers in one place with our platform, and save your computer information in 1 click.</p>
                                    
                                <div className=" gap-2 mt-4">
                                    <a onClick={() => {navigate('/register')}} className="btn btn-primary"><FontAwesomeIcon icon="fa-solid fa-chevron-right" /> Get Started</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section  p-0" style={{backgroundColor: '#2f364a'}}>
                <div class="container" style={{backgroundColor: '#2f364a'}}>
                    <div class="currency-price">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex">
                                            <div class="flex-shrink-0 me-3">
                                                <div class="avatar-xs">
                                                    <span class="avatar-title rounded-circle bg-warning bg-soft text-warning font-size-18">
                                                        <FontAwesomeIcon icon="fa-solid fa-arrow-up-long" />
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="flex-grow-1">
                                                <p class="text-muted">Constant improvement</p>
                                                <h5>Benefit from a service that evolves with the times!</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex">
                                            <div class="flex-shrink-0 me-3">
                                                <div class="avatar-xs">
                                                    <span class="avatar-title rounded-circle  text-primary font-size-18" style={{backgroundColor: '#a750f140'}}>
                                                        <FontAwesomeIcon icon="fa-solid fa-cloud-arrow-up" />
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="flex-grow-1">
                                                <p class="text-muted">High Perfomance</p>
                                                <h5>Access your remote management with optimal refresh</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex">
                                            <div class="flex-shrink-0 me-3">
                                                <div class="avatar-xs">
                                                    <span class="avatar-title rounded-circle bg-info bg-soft text-info font-size-18">
                                                        <FontAwesomeIcon icon="fa-solid fa-headphones-simple" />
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="flex-grow-1">
                                                <p class="text-muted">Support</p>
                                                <h5>Benefit from responsive support that listens to your needs</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>

            <section class="section pt-4 bg-white" id="about">
                        <div className="bg-overlay bg-light"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-center mb-5">
                                <div class="small-title">About us</div>
                                <h4>Some tool functionality</h4>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-5  ms-auto">
                            <div class="mt-4 mt-lg-0">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="card border">
                                            <div class="card-body">
                                                <div class="mb-3">
                                                    <FontAwesomeIcon icon="fa-solid fa-circle-info" class="text-success" style={{height: '22px'}} />
                                                </div>
                                                <h5>Advanced information</h5>
                                                <p class="text-muted mb-0">Retrieve machine information</p>
            
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="card border mt-lg-5">
                                            <div class="card-body">
                                                <div class="mb-3">
                                                    <FontAwesomeIcon icon="fa-solid fa-paper-plane" class="text-success" style={{height: '22px'}}/>
                                                </div>
                                                <h5>Windows text-mode command interpreter</h5>
                                                <p class="text-muted mb-0">If you forget to switch off your computer before going to work, you can do it remotely via the computer console.</p>
            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 ms-auto">
                            <div class="mt-4 mt-lg-0">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="card border">
                                            <div class="card-body">
                                                <div class="mb-3">
                                                    <FontAwesomeIcon icon="fa-solid fa-file-circle-plus" class="text-success" style={{height: '22px'}}/>
                                                </div>
                                                <h5>Executable Creation Area</h5>
                                                <p class="text-muted mb-0">Customize and create your own executable</p>
            
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="card border mt-lg-5">
                                            <div class="card-body">
                                                <div class="mb-3">
                                                    <FontAwesomeIcon icon="fa-solid fa-sitemap" class="text-success" style={{height: '22px'}}/>
                                                </div>
                                                <h5>Access other information on your computer</h5>
                                                <p class="text-muted mb-0">You'll be able to access a wide range of information right from your machine, in complete security!</p>
            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section" id="features">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-center mb-5">
                                <div class="small-title">Description</div>
                                <h4>Description of the product</h4>
                            </div>
                        </div>
                    </div>

                    <div class="row align-items-center pt-4">
                        <div class="col-md-6 col-sm-8">
                            <div>
                                <img src="assets/images/crypto/features-img/img-2.png" alt="" class="img-fluid mx-auto d-block" />
                            </div>
                        </div>
                        <div class="col-md-5 ms-auto">
                            <div class="mt-4 mt-md-auto">
                                <div class="d-flex align-items-center mb-2">
                                    <div class="features-number text-danger fw-semibold display-4 me-3"><FontAwesomeIcon icon="fa-solid fa-arrow-right" /></div>
                                    <h4 class="mb-0">What is the purpose of vnetwork ?</h4>
                                </div>
                                <p class="text-muted">The primary objective of vnetwork is to guarantee the security and stability of the service. It allows you to manage your Windows machines from a single location.</p>
                                <div class="text-muted mt-4">
                                    <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Filter on your computer to see online,offline, or all !</p>
                                    <p><i class="mdi mdi-circle-medium text-success me-1"></i>See the uptime of your machine</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <footer class="landing-footer" style={{ bottom: '-12px', width: '100%', color: 'white', textAlign: 'center' }}>
                <div class="container">

                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="mb-4 mb-lg-0">
                                <h5 class="mb-3 footer-list-title">Company</h5>
                                <ul class="list-unstyled footer-list-menu">
                                    <li><a href="https://t.me/willy4533">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="mb-4 mb-lg-0">
                                <h5 class="mb-3 footer-list-title">Resources</h5>
                                <ul class="list-unstyled footer-list-menu">
                                    <li><a onClick={() => {navigate('/privacy')}}>Privacy Policy</a></li>
                                    <li><a onClick={() => {navigate('/terms')}}>Terms & Conditions</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    <hr class="footer-border my-5" />

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-4">
                                <img src="assets/images/logo-light.png" alt="" height="40" />
                            </div>
            
                            <p class="mb-2">2023-2024 © Vnetwork.cc</p>
                        </div>

                    </div>
                </div>
            </footer>

            <div className='rightbar-overlay'></div>
            <script src='%PUBLIC_URL%/libs/jquery/jquery.min.js'></script>
            <script src='%PUBLIC_URL%/libs/bootstrap/js/bootstrap.bundle.min.js'></script>
            <script src='%PUBLIC_URL%/libs/metismenu/metisMenu.min.js'></script>
            <script src='%PUBLIC_URL%/libs/simplebar/simplebar.min.js'></script>
            <script src='%PUBLIC_URL%/assets/libs/node-waves/waves.min.js'></script>
            <script src='%PUBLIC_URL%/assets/libs/apexcharts/apexcharts.min.js'></script>

            <script src='%PUBLIC_URL%/assets/js/pages/dashboard.init.js'></script>
        </div>
    )
}

export default LandingPage
